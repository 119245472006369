import React, { useEffect } from "react";
import { Content, SubHeadWrap, BlogPage } from "./Styles";
import { Header } from "../../internal/Header/Header";
import Footer from "../LandingPage/Footer";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";

/* eslint-disable jsx-a11y/anchor-is-valid */

function TechniqueForResolution(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header>
        <BlogPage>
          <HeadWrap>
            Breaking Impasses in Settlement Conferences: Five Techniques for
            Resolution
          </HeadWrap>
          <br />
          <br />
          <Content>
            <SubHeadWrap style={{ marginBottom: "10px", marginTop: "10px" }}>
              Introduction
            </SubHeadWrap>
            <p>
              An impasse signifies a stalemate or deadlock in discussions when
              parties are unwilling to budge from their respective positions for
              stated reasons. Interestingly, impasses occur frequently during
              negotiations between parties and it is the duty of neutrals and
              third-party mediators to be skilled in the deployment of
              strategies to resolve these deadlocks.
            </p>
            <br />
            <p>
              It is necessary to understand the source of impasses before one
              discusses the host of strategies that could be utilised to break
              impasses in settlement conferences. Cognitive science offers
              insights into the existence of two information processing systems
              in human beings: System 1 is an intuitive system that
              automatically and quickly processes information using unconscious
              innate heuristics and over-learned habits to produce a judgment in
              seconds referred to as “fast thinking.” System 2 is an analytic
              system that uses reflective, conscious thought processes to solve
              complex problems, so-called “slow thinking.” The challenge for
              mediators is to help parties overcome cognitive biases and flawed
              risk assessments inherent in “fast thinking” by adopting “slow
              thinking” and therefore, avoiding the former <sup>1</sup>.
            </p>
            <SubHeadWrap style={{ marginBottom: "10px", marginTop: "10px" }}>
              Sources of impasses
            </SubHeadWrap>
            <p>
              Settlement conferences could be challenging due to the obdurate
              positions parties sometimes take in reaching a conclusion. There
              may be a disparity in the evaluation of merits i.e. of facts and
              law, by both parties in regard to the same dispute. Sometimes,
              hostility and ego clashes between the parties prior to their
              arrival at the settlement table could impede the reaching of a
              mutually beneficial settlement.
            </p>
            <br />
            <p>
              In addition, there could be hidden agendas that the parties do not
              divulge that cement their respective positions. There could also
              be the fear of cognitive overload that could make parties
              resistant to further discussion and negotiation. Sometimes, high
              emotionality around principles, values and needs may blind parties
              from understanding the terms of the dispute and prevent them from
              narrowing down to the most relevant issues and concerns. Apart
              from the above reasons, factors extraneous to the dispute
              including unrealistic expectations, unproductive tactical power
              ploys and differing styles of negotiation may create deeper
              wedges.
            </p>
            <br />
            <p>
              At such points, parties reach an impasse in the negotiation
              process which further complicates the process of settlement.
              Impasses could cause a breakdown in relations and trigger
              litigation which could result in added cost and time burdens upon
              the parties.
            </p>
            <SubHeadWrap style={{ marginBottom: "10px", marginTop: "10px" }}>
              What is the role of a neutral?
            </SubHeadWrap>
            <p>
              Keeping the above in mind, the neutral is expected to deploy an
              arsenal of skilled interventions and techniques to attempt
              resolution of the dispute by suggesting new problem- framing
              strategies, to seek more information about the dispute in order to
              re-envision the contours of the problem as well as make suitable
              settlement recommendations in keeping with the requirements of the
              parties.
            </p>
            <br />
            <p>
              As discussed by US Magistrate Judge Morton Denlow <sup>2</sup> in
              his article on breaking impasses in settlement conferences,
              certain strategies like:
            </p>
            <TextLines style={{ marginLeft: "40px", marginTop: "12px" }}>
              <ul>
                <li style={{ marginBottom: "10px" }}>
                  creating a range within which parties may approach a financial
                  settlement,
                </li>
                <li style={{ marginBottom: "10px" }}>
                  recommending a specific figure for settlement and gauging the
                  parties&#39; openness to settlement,
                </li>
                <li style={{ marginBottom: "10px" }}>
                  splitting the difference between the parties&#39; quotes in
                  such a way that both parties feel like their positions have
                  been respected and upheld,
                </li>
                <li style={{ marginBottom: "10px" }}>
                  clarifying the important objective facts so that emotions do
                  not derail the settlement process and
                </li>
                <li style={{ marginBottom: "10px" }}>
                  setting firm deadlines so that the parties do not delay a
                  possible settlement
                </li>
              </ul>
            </TextLines>
            <p>
              may prove to be useful depending on the nature of the settlement.
            </p>
            <SubHeadWrap style={{ marginBottom: "10px", marginTop: "10px" }}>
              Five Techniques for Resolution
            </SubHeadWrap>
            <p>
              Extending the discussion from the strategies above, this section
              will discuss five general techniques for breaking impasses in
              settlement conferences <sup>3</sup>:
            </p>
            <TextLines style={{ marginLeft: "40px", marginTop: "12px" }}>
              <ol>
                <li style={{ marginBottom: "10px" }}>
                  <b>Gather necessary information-</b> A mediator or negotiator
                  should gather comprehensive information about the dispute,
                  parties and extraneous details so that all actors are in the
                  know of the precise nature of the impasse. This approach is
                  meant to examine underlying values, needs, interests, power
                  struggles and eventually reach mutual ground for exploring
                  options and listing solutions.
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <b>Test parties’ assumptions-</b> The mediator could ask the
                  parties open-ended questions that explore varying
                  perspectives, probabilities, reservations and outcomes
                  relating to the impasse in order to shake biases and overcome
                  the impasse.
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <b>Encouragement to parties-</b> The mediator should encourage
                  acts of concessions between the parties so that they stay open
                  to compromise. When a mediator presents possible solutions,
                  the parties may feel encouraged to keep trying.
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <b>Discuss the alternatives-</b> The parties may be reminded
                  of time, money and opportunity costs when settlement processes
                  reach a stalemate: this also includes disruption of business,
                  family and personal relationships. They may be reminded of the
                  benefits of minimizing uncertainty and of the risks involved
                  in long-term litigation by staying focused on the benefits of
                  mediation.
                </li>
                <li>
                  <b>Conditional Offers-</b> Conditional offers are recognised
                  as the means to bracket or reframe the negotiation and as
                  suggested by J. Denlow, it may be helpful to suggest a range
                  within which parties may negotiate financial disputes or
                  entrust the mediator with providing a settlement figure so
                  that new negotiations may begin. These offers ensure that
                  channels of communication stay open between the parties.
                </li>
              </ol>
            </TextLines>
            <SubHeadWrap style={{ marginBottom: "10px", marginTop: "10px" }}>
              In conclusion
            </SubHeadWrap>
            <p>
              Even though impasses may present hindrances in the process of
              reaching a final settlement and reflect resistance to solutions
              and/or lack of creativity in finding common ground, asking,
              listening and other acts of communication could resolve the
              obstacles in the path of resolution. Impasses could also signal
              useful opportunities to neutrals to hone their skills at
              negotiation and produce practicable solutions.
            </p>
            <Block />
            <p>
              <sup>1</sup>{" "}
              https://mediationblog.kluwerarbitration.com/2019/11/06/overcoming-impasse-in-mediation/
            </p>
            <p>
              <sup>2</sup>{" "}
              https://www.jamsadr.com/files/uploads/documents/articles/denlow-breaking-impasses.pdf
            </p>
            <p>
              <sup>3</sup>{" "}
              https://www.ijlmh.com/wp-content/uploads/Impasse-Breaking-Impasse-and-Avoiding-Impasse.pdf
            </p>
          </Content>
        </BlogPage>
      </Header>
      <Footer />
    </>
  );
}

export default TechniqueForResolution;

export const ContentExample = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 14px;
`;

export const Block = styled.div`
  width: 193px;
  height: 0;
  border: 1px solid ${COLORS.INPUT_BLACK};
  margin: 3px;
  display: inline-block;
  margin-top: 12px;
  margin-bottom: 12px;
  font-family: ${theme.fonts.primaryFontRegular};
`;

export const HeadWrap = styled.div`
  text-align: center;
  font-size: 18px;
  color: ${COLORS.BTN_GREEN};
  font-family: ${theme.fonts.primaryFontBold};
  @media ${theme?.breakpoints?.sm_up} {
    font-size: 25px;
  }
`;

export const SubHeadWrapHeading = styled.div`
  text-align: left;
  font-size: 20px;
  padding-left: 1.5%;
  color: ${COLORS.BTN_GREEN};
  font-family: ${theme.fonts.primaryFontRegular};
`;

export const TextLinesData = styled.div`
  padding-left: 30px;
  margin-left: 30px;
  text-align: justify;
`;

const TextLines = styled.div`
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 16px;
  padding: 10px;
  margint-left: 0;
  @media (max-width: 600px) {
    font-size: 14px;
    padding: 0;
  }
`;
