import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef,
} from "react";
import { Slate, Editable, withReact, ReactEditor } from "slate-react";
import { createEditor, Editor, Transforms, Range } from "slate";
import { withHistory } from "slate-history";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import styled from "styled-components";
import { shortTimecode } from "../TimeCodeConverter";
import { isArray } from "lodash";
import { CustomInputField } from "../../common/FormInputs";
import { Tooltip } from "@material-ui/core";

const Index = ({
  data,
  timecodeOffset,
  showTimecodes,
  currentTime,
  showSpeakers,
  handleWordClick,
  render,
  setRender,
  setData,
  captureText,
  agentRole,
  name,
  role,
}) => {
  const editor = useMemo(() => withReact(withHistory(createEditor())), []);
  const [value, setValue] = useState([]);
  const [update, setUpdate] = useState(false);
  const MTRef = useRef(null);

  useEffect(() => {
    const response = splitContent(data, currentTime);
    setValue(response);
    setRender(false);
    if (value) {
      editor.children = value;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [render]);

  useEffect(() => {
    if (update) {
      setData(
        editor.children.map((el) => {
          return {
            id: el?.id,
            end: el?.end,
            confidence: el?.confidence,
            text: el?.children,
            start: el?.start,
            speaker: el?.speaker,
            mediaId: el?.mediaId,
            transcriptionFlag: el?.transcriptionFlag,
          };
        })
      );
      setUpdate(false);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update]);

  function generatePreviousTimingsUpToCurrent(start, end) {
    const startTimeInt = parseInt(start);
    const endTimeInt = parseInt(end);
    return `${startTimeInt} ${endTimeInt}`;
  }

  const handleOnClick = (element, index, suggestionText, text) => {
    const inputElement = document.getElementById(`suggestText+${text}`);

    const newState = [...element.children];

    newState[index] = {
      ...element.children[index],
      [suggestionText]: inputElement.value,
    };

    const pathToCurrentNode = ReactEditor.findPath(editor, element);

    const updateNode = { ...element, children: newState };

    delete updateNode.buttonIndex;

    Transforms.removeNodes(editor, { at: pathToCurrentNode });
    Transforms.insertNodes(editor, updateNode, { at: pathToCurrentNode });
    setUpdate(true);
  };

  const handleChange = (element, index) => {
    const pathToCurrentNode = ReactEditor.findPath(editor, element);

    Transforms.setNodes(
      editor,
      { buttonIndex: index },
      { at: pathToCurrentNode }
    );
  };

  const renderElement = useCallback((props) => {
    switch (props.element.type) {
      case "timedText":
        return <TimedTextElement {...props} />;
      default:
        return <DefaultElement {...{ props }} />;
    }
  }, []);

  const DefaultElement = (props) => {
    return <p {...props.attributes}>{props.children}</p>;
  };

  const TimedTextElement = (props) => {
    let startTimecode = props.element?.start;
    if (timecodeOffset) {
      startTimecode += timecodeOffset;
    }

    return (
      <React.Fragment>
        <DrawerContainer style={{ display: "flex" }} contentEditable={false}>
          {showSpeakers ? (
            <Container>
              {/* <Person style={{ fill: COLORS.BTN_GREEN, cursor: "pointer" }} /> */}
              <ProfileImage
                src={require("../../../assets/images/userPlaceholder.svg")}
              />
              <ValueSpeaker style={{ marginLeft: "5px" }}>
                {props.element.speaker}
              </ValueSpeaker>
            </Container>
          ) : null}
          {showTimecodes ? (
            <ValueTimesCode
              data-start={props.element.start}
              onClick={() => handleWordClick(startTimecode)}
            >
              {shortTimecode(startTimecode)}
            </ValueTimesCode>
          ) : null}{" "}
          {props.element?.confidence ? (
            <ValueConfidence>{`${props.element?.confidence} %`}</ValueConfidence>
          ) : null}
          <ValueIds>{props.element?.id}</ValueIds>
          {props.element.children.map(
            (item, index) =>
              item.color && (
                <div className="transcript-input">
                  <Containers>
                    <CustomInputField
                      key={index}
                      ref={MTRef}
                      id={`suggestText+${item.text}`}
                      className={"input-white"}
                      variant="outlined"
                      placeholder={
                        item?.suggestionText ? item?.suggestionText : item.text
                      }
                      onChange={(e) => handleChange(props.element, index)}
                    />
                    {props.element.children[props.element.buttonIndex] ===
                      item && (
                      <ActionButton
                        onClick={() =>
                          handleOnClick(
                            props.element,
                            index,
                            "suggestionText",
                            item.text
                          )
                        }
                      >
                        <img
                          src={require("../../../assets/images/checkBoxSelected.svg")}
                          alt="submit"
                        />
                      </ActionButton>
                    )}
                  </Containers>
                </div>
              )
          )}
        </DrawerContainer>
        <CustomTextAreaOtter
          style={{
            color: COLORS.COLOR_DARK,
          }}
          onDoubleClick={() => handleWordClick(startTimecode)}
        >
          {props.children}
        </CustomTextAreaOtter>
      </React.Fragment>
    );
  };

  const generateText = (words) => {
    return words.text;
  };

  const splitContent = (paragraphs) => {
    return paragraphs.map((paragraph, index) => ({
      speaker: paragraph.speaker,
      start: paragraph.start,
      confidence: paragraph.confidence,
      id: paragraph.id,
      end: paragraph.end,
      previousTimings: generatePreviousTimingsUpToCurrent(
        paragraph.start,
        paragraph.end
      ),
      mediaId: paragraph?.mediaId,
      transcriptionFlag: paragraph?.transcriptionFlag,
      // pre-computing the display of the formatting here so that it doesn't need to convert it in leaf render
      startTimecode: paragraph.start,
      type: "timedText",
      children: isArray(paragraph.text)
        ? paragraph.text
        : [
            {
              text: generateText(paragraph),
            },
          ],
    }));
  };

  const renderLeaf = useCallback(({ attributes, children, leaf }) => {
    if (leaf["suggestionText"] || leaf["name"]) {
      return (
        <Tooltip
          title={
            leaf["name"]
              ? `${
                  leaf["suggestionText"] ? leaf["suggestionText"] : leaf["role"]
                }  - ${leaf["name"]}`
              : leaf["suggestionText"]
          }
          //  title={leaf["suggestionText"]}
          placement="top"
        >
          <span
            className={"timecode text"}
            style={{
              borderRadius: "5px",
              backgroundColor: leaf["color"],
              border:
                children.props.parent.buttonIndex &&
                children.props.parent.children[
                  children.props.parent.buttonIndex
                ].text === leaf.text &&
                "2px solid rgba(246,180,54, 20)",
            }}
            data-start={children.props.parent.start}
            data-previous-timings={children.props.parent.previousTimings}
            {...attributes}
          >
            {children}
          </span>
        </Tooltip>
      );
    }
    return (
      <span
        className={"timecode text"}
        style={{
          borderRadius: "5px",
          backgroundColor: leaf["color"],
        }}
        data-start={children.props.parent.start}
        data-previous-timings={children.props.parent.previousTimings}
        {...attributes}
      >
        {children}
      </span>
    );
  }, []);

  const handleTimeClick = () => {
    editor.selection = null;
  };

  const highlightText = (currentTime) => {
    let boolean;
    if (currentTime) {
      for (let i = 0; i < value.length; i++) {
        if (value[i].start <= currentTime && value[i].end >= currentTime) {
          boolean = `${value[i].start} ${value[i].end}`;
          break;
        }
      }
      return boolean;
    }
  };

  const handleMouseCapture = () => {
    const { selection } = editor;
    const captureText = window.getSelection().toString();
    if (selection && window.getSelection().anchorOffset !== 0) {
      const [start, end] = Range.edges(selection);
      const [startNode] = Editor.path(editor, selection.anchor);
      const currentNode = Editor.node(editor, [startNode])[0];
      let highlightedText = [];
      let currentIndex = 0;
      const ranges = [{ start: start?.offset, end: end?.offset }];
      if (currentNode.children.length > 1) {
        // const { children } = editor.children[startNode];
        // const index = children.findIndex((node) => {
        //   if (node.text) {
        //     return node.text.includes(captureText);
        //   }
        //   return false;
        // });
        const startPath = Editor.path(editor, start);
        const currentIndex = startPath[1];
        if (currentIndex !== -1) {
          const newArray =
            currentNode.children[currentIndex].text.split(captureText);
          const newChildren = newArray.reduce((acc, part, index) => {
            if (index !== newArray.length - 1) {
              acc.push({ text: part });
              acc.push({
                text: captureText,
                // color: "#5cebdf",
                name: name,
                role: role,
                color:
                  agentRole === "claimant"
                    ? "#5cebdf"
                    : "rgba(80, 151, 255, 0.48)",
              });
            } else {
              acc.push({ text: part });
            }
            return acc;
          }, []);
          const sourceArrayCopy = [...currentNode.children];
          sourceArrayCopy[currentIndex] = newChildren;
          const updatedNode = {
            ...currentNode,
            children: sourceArrayCopy.flat(),
          };
          Transforms.removeNodes(editor, { updatedNode }, { at: [startNode] });
          Transforms.insertNodes(editor, updatedNode, { at: [startNode] });
          Transforms.setNodes(editor, { at: [startNode] });
          setUpdate(true);
        }
      } else {
        ranges.forEach((range) => {
          const { start, end } = range;
          highlightedText.push({
            text: currentNode?.children[0].text.substring(currentIndex, start),
          });
          highlightedText.push({
            text: currentNode?.children[0].text.substring(start, end),
            name: name,
            role: role,
            color:
              agentRole === "claimant" ? "#5cebdf" : "rgba(80, 151, 255, 0.48)",
            // color: "#5cebdf",
          });
          currentIndex = end;
        });
        highlightedText.push({
          text: currentNode?.children[0].text.substring(currentIndex),
        });
        const updatedNode = { ...currentNode, children: highlightedText };
        Transforms.removeNodes(editor, { updatedNode }, { at: [startNode] });
        Transforms.insertNodes(editor, updatedNode, { at: [startNode] });
        Transforms.setNodes(editor, { at: [startNode] });
        setUpdate(true);
      }
    } else {
    }
  };

  return (
    <>
      <React.Fragment>
        <style scoped>
          {`
             .timecode[data-previous-timings*="${highlightText(currentTime)}"]{
                  color:  ${COLORS.BTN_GREEN};
              }
          `}
        </style>
        {value.length !== 0 ? (
          <Slate editor={editor} value={value}>
            <Editable
              readOnly={true}
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              onMouseDown={handleTimeClick}
              onMouseUpCapture={captureText ? handleMouseCapture : false}
            />
          </Slate>
        ) : null}
      </React.Fragment>
    </>
  );
};

export default Index;

const Container = styled.div`
  display: flex;
`;
const DrawerContainer = styled.div`
  width: 100%;
`;

const ValueSpeaker = styled.span`
  width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  line-height: 1.7;
  margin-bottom: 8px;
  color: ${COLORS.TRANSCRIPT_GRAY};
  padding-right: 6px;
  @media ${theme.breakpoints.sm_down} {
    font-size: 14px;
  }
`;

const ValueTimesCode = styled.span`
  width: 80px;
  cursor: pointer;
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  line-height: 1.7;
  margin-bottom: 8px;
  color: ${COLORS.TRANSCRIPT_GRAY};
  word-break: break-all;
  padding-right: 6px;
  &:hover {
    text-decoration: underline;
  }
  @media ${theme.breakpoints.sm_down} {
    font-size: 14px;
  }
`;

const ValueConfidence = styled.span`
  width: 68px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  line-height: 1.7;
  margin-right: 10px;
  margin-bottom: 8px;
  color: ${COLORS.TRANSCRIPT_GRAY};
  word-break: break-all;
  padding-right: 6px;
  @media ${theme.breakpoints.sm_down} {
    font-size: 14px;
  }
`;

const ValueIds = styled.span`
  width: 50px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  line-height: 1.7;
  margin-bottom: 8px;
  color: ${COLORS.TRANSCRIPT_GRAY};
  word-break: break-all;
  padding-right: 5px;
  @media ${theme.breakpoints.sm_down} {
    font-size: 14px;
  }
`;

const CustomTextAreaOtter = styled.div`
  width: 85%;
  font-size: 16px;
  margin-left: 125px;
  margin-right: 25px;
  margin-bottom: 8px;
  line-height: 1.5;
  outline: none;
  border: none;
  resize: none;
  font-family: ${theme.fonts.primaryFontSemiBold};
  background-color: ${COLORS.PRIMARY_WHITE};
  @media ${theme.breakpoints.sm_down} {
    margin-left: 30px;
    font-size: 14px;
  }
`;

export const QuitContainer = styled.div`
  padding: 18px;
  margin-left: 117px;
  margin-right: 25px;
  border-radius: 7px;
  background-color: ${COLORS.LIGHT_BLUE};
  border: solid 1px ${COLORS.COLOR_DARK};
  margin-bottom: 12px;
  max-width: 80%;
`;

export const ProfileImage = styled.img`
  width: 20px;
  height: 25px;
  border-radius: 50%;
  object-fit: contain;
  margin-right: 4px;
  margin-left: 5px;
`;

const Containers = styled.div`
  margin: 0 15px;
  outline: none;
  border-radius: 8px;
  display: flex;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
  }
`;

export const ActionButton = styled.button`
  border: none;
  background: none;
  outline: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: 12px;
`;
