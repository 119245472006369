import React, { useEffect } from "react";
import { Content, SubHeadWrap, BlogPage } from "./Styles";
import { Header } from "../../internal/Header/Header";
import Footer from "../LandingPage/Footer";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";

/* eslint-disable jsx-a11y/anchor-is-valid */

function ExpertsInArbitration(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header>
        <BlogPage>
          <HeadWrap>SUBJECT MATTER EXPERTS IN ARBITRATIONS</HeadWrap>
          <br />
          <br />
          <Content>
            <p>
              The increase in the volume of global trade has led to a
              corresponding rise in commercial and financial disputes being
              referred to various mechanisms of dispute resolution. Subject
              matter experts&#39; contribution as neutrals, in some cases, was
              found to be crucial to proper economic valuation and assessment of
              the nature and terms of the disputes. Article 26 of the UNCITRAL
              Model Law on International Commercial Arbitration discusses the
              provision of experts appointed by the arbitral tribunal to offer
              expertise on issues identified by the tribunal as being germane to
              the process of determination. The expert, at the instance of
              parties, may also be requested to participate in hearings for the
              purposes of testifying and being questioned by the opposite
              parties.
            </p>
            <br />
            <p>
              In India, the Arbitration and Conciliation Act,{" "}
              <span style={{ fontStyle: "italic" }}>1996</span> (‘A&amp;C’ Act)
              whose provisions are based on the UNCITRAL Model Law, was enacted
              in order to facilitate speedy and effective Dispute Resolution to
              reduce the burden of litigation in courts. Specific classes of
              disputes definitely gain from the opinions of experts and expert
              witnesses who provide subject matter expertise of a scientific,
              technical, legal and financial nature due to the increasing
              complexity of cross-border transactions. The Explanatory Note by
              the UNCITRAL Secretariat on the 1985 Model Law, amended in 2006
              provides in paragraph 33, the ethical obligations placed upon the
              experts- that of ensuring fairness, objectivity and impartiality
              in proceedings.
            </p>
            <br />
            <p>
              The expert should provide their report and testimony with due care
              and consideration since their testimony and advice could help
              define the nature of the claim/defence in keeping with the limits
              of the arbitration and could influence the eventual outcome of the
              proceedings.
            </p>
            <SubHeadWrap style={{ marginBottom: "10px", marginTop: "10px" }}>
              The importance of the opinion of subject matter experts as
              provided in the Indian Evidence Act, 1872
            </SubHeadWrap>
            <p>
              The admissibility and relevancy of expert opinion during trial has
              been acknowledged in the Indian Evidence Act under Sections 45, 46
              and 51. As held in State of{" "}
              <span style={{ fontStyle: "italic" }}>
                HP vs Jai Lal &amp; Ors<sup>1</sup>
              </span>{" "}
              , while treating the evidence of an expert witness, it has to be
              shown that he has made a special study of the subject or acquired
              a special experience therein or in other words that he is skilled
              and has adequate knowledge of the subject.
            </p>
            <br />
            <p>
              Under Section 45, the expert&#39;s opinion is seen as helpful to
              the court to form an opinion upon a point of foreign law, art,
              science, finger impressions and handwriting. Section 46 discusses
              instances when the opinions of experts have a bearing upon facts
              and Section 51 shows the relevancy of the basis of the
              expert&#39;s opinion, i.e. the ground on which their opinion is
              based.
            </p>
            <br />
            <p>
              Therefore, the importance of expert opinion in guiding the
              proceeding is underlined even though the final opinion of the
              expert concerned is not binding on the judge. Having perused the
              materials put forth by the expert on the basis of which the
              conclusion is formed, the court, although not an expert, may form
              its judgment by its own examination.<sup>2</sup>
            </p>
            <br />
            <p>
              This article will look closely at the options available to enlist
              an expert’s guidance in legal capacity in both domestic and
              international contexts. Experts could be brought to participate in
              an arbitration for the following reasons:
            </p>
            <SubHeadWrap style={{ marginBottom: "10px", marginTop: "10px" }}>
              To counsel and advise the parties in order to strengthen their
              claim or defence
            </SubHeadWrap>
            <p>
              Parties might find the services of a subject matter expert in
              arbitration useful in a consultative capacity even before the
              commencement of arbitral proceedings due to their in-depth
              understanding of the nuances and complexities of the issues
              concerned. This could help them bolster their defence and
              strategize effectively thus saving valuable time and costs during
              arbitration. The experts would then owe a primary duty to the
              parties who appoint them and act as instructing advocates in this
              scenario. Article 21.2 of the London Court of International
              Arbitration (‘LCIA’) Rules, apart from delineating similar
              provisions as other institutional Rules on the above mentioned
              subject matter, stipulates that any expert should remain impartial
              and independent in the presentation of opinions and the experts
              shall sign a written declaration to such effect.
            </p>
            <br />
            <p>
              The A &amp; C Act does not provide for circumstances when parties
              may appoint experts for their own requirements.
            </p>
            <SubHeadWrap style={{ marginBottom: "10px", marginTop: "10px" }}>
              To be called upon by the tribunal to assist in matters
            </SubHeadWrap>
            <p>
              Rule 26 of the Singapore International Arbitration Rules
              enumerates that the tribunal could appoint experts to report on
              issues and offer required information, and these experts could be
              provided access to relevant documents, goods or property for
              inspection. The Tribunal also has the discretion to summon the
              expert for a hearing where they could be examined by the parties.
            </p>
            <br />
            <p>
              In{" "}
              <span style={{ fontStyle: "italic" }}>
                Keshav Dutt vs State of Haryana<sup>3</sup>
              </span>{" "}
              , the Supreme Court of India discusses whether the report of a
              handwriting expert could be relied on, when he was not
              cross-examined as a proper witness. In{" "}
              <span style={{ fontStyle: "italic" }}>
                Ramesh Chandra Agrawal vs Regency Hospital Ltd. &amp; Ors
                <sup>4</sup>
              </span>{" "}
              while explicating on the nature of medical expertise, the Court
              observed that the expert must be within a recognized field of
              expertise, the evidence presented must be based on reliable
              principles and that the expert must be qualified in that
              discipline.
            </p>
            <br />
            <p>
              Section 26 of the A &amp; C Act, in a similar fashion, enumerates
              the power of the arbitral tribunal to appoint experts to present a
              report on specific issues to be determined by the arbitral
              tribunal. This provision is similar to Article 26 of the UNCITRAL
              Model Law but is restricted to only the role and powers of the
              arbitral tribunal.
            </p>
            <SubHeadWrap style={{ marginBottom: "10px", marginTop: "10px" }}>
              To offer expert testimony
            </SubHeadWrap>
            <p>
              Article 25 of the Rules of Arbitration of the International
              Chamber of Commerce provides for establishing the facts of the
              case and Article 25(3) stipulates that the arbitral tribunal
              should hear witnesses and experts appointed by the parties after
              due summons. This provides opportunity to the parties to appoint
              experts to strengthen their claims/defence.
            </p>
            <br />
            <p>
              Article 5 of the International Bar Association Rules delineates
              the providing of evidence on specific issues by party-appointed
              experts. There are precise stipulations regarding the submission
              of reports, evidence and testimony to be followed by
              party-appointed experts. Article 6 of the IBA Rules discusses
              experts appointed by the Tribunal for assistance on designated
              issues and the requirements are similar to institutional rules
              from other international contexts.
            </p>
            <br />
            <p>
              In the Indian context, Section 27(2) of the A &amp; C Act provides
              for an expert witness to offer testimony in the event of the party
              or arbitral tribunal applying for court assistance in taking
              evidence. It has been laid down that without examining the expert
              as a witness in Court, no reliance can be placed on an opinion
              alone<sip>5</sip>.
            </p>
            <SubHeadWrap style={{ marginBottom: "10px", marginTop: "10px" }}>
              In Conclusion,
            </SubHeadWrap>
            <p>
              Whether in the matter of providing a report, testimony or opinion,
              the subject matter expert called upon to assist during the
              arbitration is required to embody integrity, impartiality and
              independence in the formulation of their statement. The parties or
              arbitral tribunal may take recourse to the expert’s opinion
              depending on the need and nature of inquiry, claim or defence and
              evidence and thus ease the process of arbitration.
            </p>
            <Block />
            <p>
              <sup>1</sup> (1999) 7 SCC 280
            </p>
            <p>
              <sup>2</sup> Titli v. Jones (AIR 1934 All 237)
            </p>
            <p>
              <sup>3</sup> Criminal Appeal No. 1560 Of 2010, (Special Leave
              Petition (Crl.) No.2742 Of 2010)
            </p>
            <p>
              <sup>4</sup> Civil Appeal NO. 5991 OF 2002 76.
            </p>
            <p>
              <sup>5</sup> State of Maharashtra v. Damu s/o Gopinath Shinde and
              others, AIR 2000 SC 1691 at page 1700
            </p>
          </Content>
        </BlogPage>
      </Header>
      <Footer />
    </>
  );
}

export default ExpertsInArbitration;

export const ContentExample = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 14px;
`;

export const Block = styled.div`
  width: 193px;
  height: 0;
  border: 1px solid ${COLORS.INPUT_BLACK};
  margin: 3px;
  display: inline-block;
  margin-top: 12px;
  margin-bottom: 12px;
  font-family: ${theme.fonts.primaryFontRegular};
`;

export const HeadWrap = styled.div`
  text-align: center;
  font-size: 18px;
  color: ${COLORS.BTN_GREEN};
  font-family: ${theme.fonts.primaryFontBold};
  @media ${theme?.breakpoints?.sm_up} {
    font-size: 25px;
  }
`;

export const SubHeadWrapHeading = styled.div`
  text-align: left;
  font-size: 20px;
  padding-left: 1.5%;
  color: ${COLORS.BTN_GREEN};
  font-family: ${theme.fonts.primaryFontRegular};
`;

export const TextLinesData = styled.div`
  padding-left: 30px;
  margin-left: 30px;
  text-align: justify;
`;
