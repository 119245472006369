import React, { useState, useEffect, useRef } from "react";
import {
  PageWrapper,
  Heading,
  TableWrapper,
  Footer,
  Hr,
  Row,
  TextWrapper,
  Text,
  TextBold,
  HyperLink,
  StyledTitle,
  Payment,
  PayLater,
  Button,
  TopCenter,
} from "./Styles";
import { PrimaryCTAButton } from "../../common/Buttons";
import CustomTable from "../../common/CustomTable/CustomTable";
import { Header } from "../Header/Header";
import useRazorPay from "../../../hooks/useRazorPay";
import queryString from "query-string";
import CaseService from "../../../services/CaseService";
import _ from "lodash";
import CartService from "../../../services/CartService";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import {
  getErrorMessage,
  parseTimeStamp,
  renderStatus,
  numberFormat,
} from "../../../helpers/functions";
import {
  addAllCart,
  addCartItems,
  removeAllCart,
  removeCartItems,
} from "./functions";
import useUser from "../../../hooks/useUser";
import moment from "moment";
import useCartCount from "../../../hooks/useCartCount";
import { navigate } from "@reach/router";
import { CLAIMENT_STR, PAYMENT_STATUS_CART } from "../../../helpers/constants";
import labels from "../../../helpers/labels.json";
import { CartSplitUp } from "../CartSplitUp";
import useAlert from "../../../hooks/useAlert";

async function getCases(query = "") {
  try {
    const response = await CaseService.getCases(query);
    return { ...response };
  } catch (error) {
    throw error;
  }
}
const Index = () => {
  const openRazorPay = useRazorPay();
  const [state, setState] = useState();
  const [caseItems, setCaseItems] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [cartDetails, setCartDetails] = useState({});
  const { userObject } = useUser();
  const placeholderText = "Search Cart";
  const { setLoader } = useLoader();
  const MTRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const { setTriggerCount } = useCartCount();
  const { showAlert, hideAlert } = useAlert();
  async function getCart(toChange = true) {
    try {
      const response = await CartService.getCart();
      if (response) {
        if (toChange) MTRef.current.onSearchChange("");
        setCartDetails(response.cart);
        setCaseItems(response.cartItems);
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    }
  }
  useEffect(() => {
    async function unSelectAll() {
      try {
        await removeAllCart([]);
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      }
    }
    unSelectAll();
    // commented for https://app.clickup.com/t/3qev6h
    // getCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state?.message) {
      enqueueSnackbar(state.message, {
        variant: "error",
      });
      navigate("/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const columns = [
    {
      field: "id",
      title: labels["table.case_id"],
      tooltip: "Unique Identifier for the Case across JustAct platform",
      sorting: false,
      render: (rowData) => (
        <HyperLink
          onClick={() =>
            navigate(
              `/dashboard/cases/${rowData.id}?caseType=${rowData?.resolutionKind}`
            )
          }
        >
          <TopCenter>{rowData.id}</TopCenter>
        </HyperLink>
      ),
    },
    {
      field: "title",
      title: labels["table.case_title"],
      sorting: false,
      render: (rowData) => (
        <StyledTitle onClick={() => navigate(`/dashboard/cases/${rowData.id}`)}>
          {rowData.title}
        </StyledTitle>
      ),
    },
    {
      field: "paryname",
      title: labels["table.party_name"],
      sorting: false,
      render: (rowData) => (
        <StyledTitle style={{ cursor: "default" }}>
          {rowData?.agentRole === CLAIMENT_STR ? (
            rowData?.claimantParty?.name
          ) : (
            <>
              {rowData?.respondentParty?.name ? (
                rowData.respondentParty.name
              ) : rowData?.respondentName ? (
                rowData.respondentName
              ) : (
                <div style={{ marginLeft: 35 }}>-</div>
              )}
            </>
          )}
        </StyledTitle>
      ),
    },
    {
      field: "amount",
      title: labels["table.amount"],
      sorting: false,
      render: (rowData) =>
        numberFormat(
          parseFloat(rowData?.fee?.total).toFixed(2),
          rowData?.currencyUnit
        ),
    },
    {
      field: "created_at",
      title: labels["table.due"],
      sorting: true,
      render: (rowData) =>
        rowData.submittedOn
          ? moment(rowData.submittedOn).add(7, "days").format("DD/MM/YYYY")
          : moment(parseTimeStamp(rowData.created_at))
              .add(7, "days")
              .format("DD/MM/YYYY"),
    },
    {
      field: "details",
      title: labels["table.description"],
      sorting: false,
      render: (rowData) => (
        <>
          {rowData?.subscriptionKind === "adhoc"
            ? "Platform Fee"
            : renderStatus(
                PAYMENT_STATUS_CART[rowData?.status]?.value,
                rowData
              )}
        </>
      ),
    },
  ];

  function getCheckedData(rowData) {
    const found = caseItems.find((item) => item.caseId === rowData.id);
    if (found) {
      return true;
    }
    return false;
  }

  const data = (query) =>
    new Promise((resolve) => {
      let params = {
        page: query.page + 1,
        perPage: 50,
        inCart: true,
      };
      if (query.search) {
        params.search = query.search;
      }
      if (query.orderBy?.field) {
        params.sort = query.orderBy.field;
        params.sortDirection = query.orderDirection;
      }

      let stringParams = `?${queryString.stringify(params)}`;
      getCases(stringParams)
        .then(async (result) => {
          if (result) {
            const rowData = result?.data?.map((row) => ({
              ...row,
              tableData: { checked: getCheckedData(row) },
            }));
            resolve({
              data: rowData,
              page: result.page - 1,
              total: result.total,
              lastPage: result.lastPage,
            });
            setState({
              data: rowData,
              page: result.page - 1,
              total: result.total,
              lastPage: result.lastPage,
            });
          }
        })
        .catch((error) => {
          enqueueSnackbar(getErrorMessage(error), {
            variant: "error",
          });
          setState({ data: [], lastPage: 1, page: 1, perPage: 10, total: 0 });
          resolve({
            data: [],
            page: 0,
            total: 0,
          });
        });
    });

  async function capturePayment(rzp_res) {
    document.body.style.overflow = null;
    try {
      setLoader({ state: true, message: "Capturing Payment..." });
      const capture_res = await CartService.capture(rzp_res);
      if (capture_res) {
        navigate("/dashboard/cases");
        enqueueSnackbar(capture_res.message, {
          variant: "success",
        });
        setTriggerCount(true);
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  async function handlePay() {
    try {
      setLoader({ state: true, message: "Initiating Payment..." });
      const cart_res = await CartService.initiate();
      if (cart_res) {
        setCartDetails(cart_res);
        setLoader({ state: false });
        openRazorPay({
          order_id: cart_res.razorpayOrderId,
          name: userObject?.name,
          email: userObject?.email,
          contact: userObject?.mobile,
          handler: capturePayment,
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  async function handleCaseSelection(cases, selectedCase) {
    if (cases?.length >= 1 && !selectedCase) {
      try {
        setLoader({ state: true, message: "Modifying Cart..." });
        await addAllCart([]);
        // const payload = {
        //   exceptCaseIds: []
        // };
        // const response = await CartService.addAllCart(payload);
        // if (response.cartItem) {
        //   return response.cartItem;
        // }
        // if (response) {
        //   setLoader({ state: false });
        //   enqueueSnackbar(response.message, {
        //     variant: "success"
        //   });
        // }
        await getCart(true);
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
        await removeAllCart([]);
        await getCart(true);
      } finally {
        setLoader({ state: false });
      }
    } else if (cases?.length === 0 && !selectedCase) {
      try {
        setLoader({ state: true, message: "Modifying Cart..." });
        await removeAllCart([]);
        await getCart(true);
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
        await getCart(true);
      } finally {
        setLoader({ state: false });
      }
    } else {
      try {
        setLoader({ state: true, message: "Modifying Cart..." });
        let newly_added_cases = [];
        let newly_removed_cases = [];
        if (selectedCase?.tableData?.checked) {
          newly_added_cases = [selectedCase];
        } else {
          newly_removed_cases = _.filter(
            caseItems,
            (c) => selectedCase?.id === c.caseId
          );
        }
        let cart_items = [];
        if (newly_added_cases.length) {
          cart_items = await addCartItems(newly_added_cases);
          setCaseItems([...caseItems, ...cart_items]);
        }
        if (newly_removed_cases.length) {
          await removeCartItems(newly_removed_cases);
          const afterRemovedItems = caseItems.filter(
            (el) => el.caseId !== selectedCase.id
          );
          setCaseItems(afterRemovedItems);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
        await getCart(true);
      } finally {
        setLoader({ state: false });
      }
    }
  }

  const detailPanel = [
    {
      tooltip: "View detailed split up...",
      render: (rowData) => {
        return <CartSplitUp data={rowData} />;
      },
    },
  ];

  const generateProfomaConfirmation = () => {
    showAlert({
      heading: "You have requested to pay later",
      desc: `Upon choosing this option, a proforma invoice shall be generated which can be viewed in Payments.`,
      //desc: `Proceeding further will generate a proforma invoice that you can pay later.`,
      primaryBtnText: "Yes, Generate",
      secondaryBtnText: "Cancel",
      clickSecondarybtn: () => hideAlert(),
      clickPrimaryBtn: () => {
        hideAlert();
        generateProforma();
      },
      isOpen: true,
      descriptionTextStyle: {
        marginBottom: 20,
      },
      onDialogClose: () => hideAlert(),
    });
  };

  const generateProforma = async () => {
    try {
      setLoader({ state: true, message: "Generating Proforma Invoice..." });
      const result = await CartService.generatePerforma();
      if (result?.status === "proformaGenerated") {
        enqueueSnackbar("Profoma invoice is generated Successfully", {
          variant: "success",
        });
        navigate("/dashboard/payments");
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  };

  return (
    <Header showSidebar>
      <PageWrapper style={{ position: "relative" }}>
        <Heading>{"Payments"}</Heading>
        <TableWrapper>
          <CustomTable
            selection
            onSelectionChange={handleCaseSelection}
            // pageSize={50}
            {...{
              columns,
              data,
              state,
              placeholderText,
              MTRef,
            }}
            pageSize={state?.data?.length === 0 ? 10 : 50}
            detailPanel={detailPanel}
            singularTitle=""
            pluralTitle={labels.cases}
            hideFilterBar
            //noToolbar
            top={"-25px"}
            left={false}
          />
        </TableWrapper>
        <Footer>
          <Hr />
          <Payment>
            <PayLater>
              <div>Would you like to pay later?</div>
              <HyperLink
                style={{ marginTop: 15, width: 200 }}
                disabled={!caseItems.length}
                onClick={() =>
                  caseItems.length && generateProfomaConfirmation()
                }
              >
                Generate Proforma Invoice
              </HyperLink>
            </PayLater>
            <Row>
              <div>
                <TextWrapper>
                  <Text>{labels.payable_amount}: </Text>
                  <TextBold>
                    {caseItems[0]?.fee?.unit ? caseItems[0]?.fee?.unit : "INR"}{" "}
                    {_.sum(_.map(caseItems, "amount")).toFixed(2)}
                  </TextBold>
                </TextWrapper>
                <div className="highlighted">
                  *All line items include 18% GST
                </div>
              </div>
              <Button>
                <PrimaryCTAButton
                  disabled={!caseItems.length}
                  style={{
                    width: "100%",
                  }}
                  onClick={handlePay}
                >
                  {labels.proceed}
                </PrimaryCTAButton>
              </Button>
            </Row>
          </Payment>
        </Footer>
      </PageWrapper>
    </Header>
  );
};

export default Index;
