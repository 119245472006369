import { CLAIMENT_STR } from "../../../helpers/constants";
import _ from "lodash";

export function getCaseLabelByStatus({
  detailedStage,
  agentRole,
  uploadedDocuments,
  subscriptionKind,
}) {
  switch (detailedStage) {
    case "draft":
      return "Draft";
    case "pendingRegistrationFee":
      return "Awaiting Case Fee";
    case "negotiationReached":
      return subscriptionKind === "adhoc"
        ? "Settled"
        : "Awaiting Agreement Fee";
    case "negotiationOngoing":
      return "Under Resolution";
    case "claimantPaidNegotiationSuccessFee":
      return uploadedDocuments && uploadedDocuments.length > 0
        ? "Settled"
        : agentRole === CLAIMENT_STR
        ? "Awaiting Agreement"
        : "Awaiting Agreement Fee";
    case "respondentPaidNegotiationSuccessFee":
      return uploadedDocuments && uploadedDocuments.length > 0
        ? "Settled"
        : agentRole !== CLAIMENT_STR
        ? "Awaiting Agreement"
        : "Awaiting Agreement Fee";
    case "awaitingRespondentAcceptance":
      return agentRole === "respondent"
        ? "Awaiting Case Fee"
        : "Waiting For Respondent";
    case "bothPaidNegotiationSuccessFee":
      return uploadedDocuments && uploadedDocuments.length > 0
        ? "Settled"
        : "Awaiting Agreement";
    case "waitingForCaseApproval":
      return "Waiting For Case Approval";
    case "waitingForArbitratorConfirmation":
      return "Waiting for Arbitrator Confirmation";
    case "noticeToArbitrate":
      return "Notice to Arbitrate";
    case "arbitrationReferenced":
      return "Arbitration Referenced";
    case "arbitrationNominated":
      return "Arbitrator Nominated";
    case "arbitrationConfirmed":
      return "Arbitrator Confirmed";
    case "firstNoticeSent":
      return "First Notice Sent";
    case "statementOfClaimFiled":
      return "Statement of Claim Filed";
    case "awaitingForSec17Details":
      return "Awaiting for Sec 17 details";
    case "sec17PetitionFiled":
      return "Sec 17 Petition Filed";
    case "sec17Ordered":
      return "Sec 17 Ordered";
    case "awaitingSec17Execution":
      return "Awaiting Sec 17 Execution";
    case "sec17ExtensionMemoFiled":
      return "Sec 17 Extension Memo Filed";
    case "sec17ExtensionMemoOrdered":
      return "Sec 17 Extension Memo Ordered";
    case "fsOrdered":
      return "FS Ordered";
    case "attachmentOrdered":
      return "Attachment Ordered";
    case "garnisheeProhibitaryOrder":
      return "Garnishee Prohibitary Order";
    case "garnisheeFinalOrder":
      return "Garnishee Final Order";
    case "sec17Closed":
      return "Sec 17 Closed";
    case "statementOfDefenceFiled":
      return "Statement of Defence Filed";
    case "rejoinderFromClaimant":
      return "Rejoinder from Claimant";
    case "surrejoinderFromRespondent":
      return "Surrejoinder From Respondent";
    case "2ndNoticeMOM":
      return "2nd Notice MOM";
    case "evidenceFiledByClaimant":
      return "Evidence Filed by Claimant";
    case "evidenceFiledByRespondent":
      return "Evidence Filed by Respondent";
    case "crossExamination":
      return "Cross Examination";
    case "argumentsByClaimant":
      return "Arguments by Claimant";
    case "argumentsByRespondent":
      return "Arguments by Respondent";
    case "reservedForAward":
      return "Reserved for Award";
    case "awardPassed":
      return "Award Passed";
    case "quitByClaimant":
      return "Quit by Claimant";
    case "quitByRespondent":
      return "Quit by Respondent";
    case "resolutionFailed":
      return "Resolution Failed";
    case "closedByCaseManager":
      return "Closed By CaseManager";
    case "settledAndWithdrawn":
      return "Settled & Withdrawn";
    case "updatedAndWithdrawn":
      return "Updated & Withdrawn";
    default:
      return _.startCase(detailedStage);
  }
}

export const polarToCartesian = (percent) => {
  const angle = percent * 2 * Math.PI - Math.PI / 2;
  return [16 + Math.cos(angle) * 16, 16 + Math.sin(angle) * 16];
};

export const getColorForKey = (key) => {
  const colorMap = {
    "Case Initiated": "#98DDCA",
    "Case In progress": "#D5ECC2",
    "Case Completed": "#FFD3B4",
    "Awaiting Case Fee": "#8DECB4",
    "Resolution Closed": "#FFAAA7",
    "Awaiting Payment": "#B5CFB7",
    "Waiting For Case Approval": "#9CA986",
    "Waiting For Respondent": "#DCA47C",
    "Notice to Arbitrate": "#EF5A6F",
    "Arbitration Referenced": "#667BC6",
    "Arbitrator Nominated": "#3AA6B9",
    "Arbitrator Confirmed": "#A1DD70",
    "Waiting for Arbitrator Confirmation": "#B1B2FF",
    "First Notice Sent": "#5A639C",
    "Statement of Claim Filed": "#FFDE95",
    "Awaiting for Sec 17 details": "#D2649A",
    "Sec 17 Petition Filed": "#7BD3EA",
    "Sec 17 Ordered": "#FBA834",
    "Awaiting Sec 17 Execution": "#C3FF93",
    "Sec 17 Extension Memo Filed": "#15F5BA",
    "Sec 17 Extension Memo Ordered": "#40128B",
    "FS Ordered": "#FFFFD0",
    "Attachment Ordered": "#B3FFAE",
    "Garnishee Prohibitary Order": "#49FF00",
    "Garnishee Final Order": "#FF00E4",
    "Sec 17 Closed": "#D7E9B9",
    "Statement of Defence Filed": "#D4E7C5",
    "Rejoinder from Claimant": "#C3ACD0",
    "Surrejoinder From Respondent": "#BEADFA",
    "2nd Notice MOM": "#CDF0EA",
    "Evidence Filed by Claimant": "#8C3061",
    "Evidence Filed by Respondent": "#677D6A",
    "Cross Examination": "#A2678A",
    "Arguments by Claimant": "#DEF5E5",
    "Arguments by Respondent": "#B6EADA",
    "Under Resolution": "#CDE8E5",
    "Negotiation Reached": "#8B9A46",
    "Claimant paid Success Fee": "#FF7D29",
    "Respondent paid Success Fee": "#B784B7",
    "Both paid NegotiationFee": "#DC84F3",
    "Reserved for Award": "#F4DFC8",
    "Award Passed": "#E6FF94",
    "Quit by Claimant": "#DCF2F1",
    "Quit by Respondent": "#005B41",
    "Resolution Failed": "#9B4444",
    "Closed By CaseManager": "#E8A0BF",
    "Settled & Withdrawn": "#95D2B3",
    "Updated & Withdrawn": "#AEE1FC",
    "Awaiting Agreement": "#93B5C6",
  };
  return colorMap[key] || getRandomColor();
};

function getRandomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
