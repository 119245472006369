import React, { Fragment, useState, useRef } from "react";
import {
  ButtonWrapper,
  AcceptButton,
  DeclineButton,
  StyledTitle,
  TableWrapper,
  Padding,
  Container,
} from "./Styles";
// import { Heading } from "../../../styles/component/style";
import _ from "lodash";
import { navigate } from "@reach/router";
import ActionBar from "../../common/ActionBar";
import CustomTable from "../../common/CustomTable/CustomTable";
import moment from "moment";
import CaseService from "../../../services/CaseService";
import queryString from "query-string";
import useLoader from "../../../hooks/useLoader";
import {
  getErrorMessage,
  parseTimeStamp,
  numberFormat,
} from "../../../helpers/functions";
import { useSnackbar } from "notistack";
import AssignPartyModal from "./components/AssignPartyModal";
import labels from "../../../helpers/labels.json";
import useAlert from "../../../hooks/useAlert";
import { Tooltip } from "@material-ui/core";
import { HyperLink } from "../../common/styled-components";
import CaseDescriptionModal from "./components/CaseDescriptionModal";
import AgentListModal from "../../common/AgentListModal";
import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";

async function getCases(stringParams) {
  try {
    const response = await CaseService.caseRequests(stringParams);
    return { ...response };
  } catch (error) {
    throw error;
  }
}

const Index = () => {
  const breadcrumbs = [_.startCase(labels.home), labels.case_invites_received];
  const [state, setState] = useState({});
  const [modal, setModal] = useState({ state: false });
  const MTRef = useRef();
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const { hideAlert, showAlert } = useAlert();
  const [caseDescriptionModal, setCaseDescriptionModal] = useState({
    state: false,
  });
  const [agentModal, setAgentModal] = useState({ state: false, agents: [] });
  const placeholderText = "Search Cases";

  async function requestForCaseReopen(caseId) {
    try {
      setLoader({ state: true, message: "case reopen..." });
      const response = await CaseService.requestForCaseReopen(caseId);
      if (response) {
        enqueueSnackbar(response.message, {
          variant: "success",
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  const columns = [
    {
      field: "id",
      title: labels["table.case_id"],
      render: (rowData) => <Padding>{rowData.id}</Padding>,
      sorting: false,
      tooltip: "Unique Identifier for the Case across JustAct platform",
      headerStyle: {
        paddingLeft: "28px",
        width: 120,
        display: "flex",
      },
      cellStyle: {
        paddingLeft: "28px",
        width: 120,
      },
    },
    {
      field: "title",
      title: labels["table.case_title"],
      sorting: false,
      render: (rowData) => <StyledTitle>{rowData.title}</StyledTitle>,
      headerStyle: {
        maxWidth: 270,
      },
      cellStyle: {
        maxWidth: 250,
      },
    },
    {
      field: "owner.name",
      title: labels["role.claimant"],
      sorting: false,
      render: (rowData) => <>{_.startCase(rowData.owner.name)}</>,
    },
    {
      field: "respondentName",
      title: labels["role.respondent"],
      sorting: false,
      render: (rowData) => (
        <>
          {rowData?.respondentParty?.name ? (
            rowData.respondentParty.name
          ) : rowData?.respondentName ? (
            rowData.respondentName
          ) : rowData?.respondentParties?.length ? (
            rowData?.respondentParties[0]?.name
          ) : (
            <div style={{ marginLeft: 35 }}>-</div>
          )}
        </>
      ),
    },
    {
      field: "resolutionKind",
      title: labels["table.caseType"],
      render: (rowData) => <>{_.startCase(rowData.resolutionKind)}</>,
      headerStyle: {
        minWidth: 140,
      },
      sorting: false,
    },
    {
      field: "totalClaimValue",
      title: labels["table.claim_value"],
      tooltip: "Claim Value of the Case",
      render: (rowData) => {
        const caseItems = rowData.totalClaimValue;
        if (caseItems)
          return numberFormat(
            parseFloat(caseItems).toFixed(2),
            rowData?.currencyUnit
          );
        else return "Non Monetary";
      },
      headerStyle: {
        minWidth: 140,
      },
    },
    {
      field: "submittedOn",
      title: labels["table.received_on"],
      render: (rowData) => (
        <>
          {rowData?.submittedOn
            ? moment(rowData.submittedOn).format("DD/MM/YYYY")
            : moment(parseTimeStamp(rowData.created_at)).format("DD/MM/YYYY")}
        </>
      ),
      headerStyle: {
        minWidth: 120,
      },
    },
    {
      field: "",
      sorting: false,
      title: labels["table.description"],
      render: (rowData) => (
        <HyperLink
          onClick={() => {
            setCaseDescriptionModal({ state: true, caseDetails: rowData });
            setAgentModal({
              state: false,
              agents: rowData?.claimantParty?.agents,
            });
          }}
        >
          View
        </HyperLink>
      ),
    },
    {
      field: "actions",
      title: labels["table.actions"],
      sorting: false,
      headerStyle: {
        textAlign: "center",
      },
      render: (rowData) => (
        <ButtonWrapper>
          <Tooltip
            title={
              moment(rowData?.counterClaimDeadLine).format("YYYY-MM-DD") <
              moment(new Date()).format("YYYY-MM-DD")
                ? "Request for case Reopen"
                : "Accept Case"
            }
          >
            <AcceptButton
              onClick={() =>
                rowData.resolutionKind === "arbitration"
                  ? moment(rowData?.counterClaimDeadLine).format("YYYY-MM-DD") <
                    moment(new Date()).format("YYYY-MM-DD")
                    ? requestForCaseReopen(rowData.id)
                    : navigate(
                        `cases/accept/${rowData.id}?caseType=arbitration`,
                        { state: rowData }
                      )
                  : handleAccept(rowData)
              }
            >
              <img
                src={require("../../../assets/images/checkBoxSelected.svg")}
                alt="accept"
              />
            </AcceptButton>
          </Tooltip>
          <Tooltip title="Decline Case">
            <DeclineButton
              onClick={() =>
                showAlert({
                  heading: "Decline Case?",
                  desc: `Are you sure you want to decline this case invitation? A case once declined cannot be accepted later.`,
                  // desc:
                  //   "Are you sure you want to decline this case invite? This operation cannot be undone.",
                  primaryBtnText: "Decline",
                  secondaryBtnText: "Cancel",
                  clickSecondarybtn: () => hideAlert(),
                  clickPrimaryBtn: () => {
                    handleDecline(rowData.id);
                  },
                  isOpen: true,
                  onDialogClose: () => hideAlert(),
                })
              }
            >
              <img
                src={require("../../../assets/images/closeButton.svg")}
                alt="decline"
              />
            </DeclineButton>
          </Tooltip>
        </ButtonWrapper>
      ),
    },
  ];

  const data = (query) =>
    new Promise((resolve) => {
      let params = {
        page: query.page + 1,
        perPage: 10,
      };
      if (query.search) {
        params.search = query.search;
      }
      if (query.orderBy?.field) {
        params.sort = query.orderBy.field;
        params.sortDirection = query.orderDirection;
      }
      let stringParams = "";
      if (!_.isEmpty(params)) {
        stringParams = `?${queryString.stringify(params)}`;
      }
      getCases(stringParams)
        .then((result) => {
          if (result) {
            resolve({
              data: result.data,
              page: result.page - 1,
              total: result.total,
            });
            setState(result);
          }
        })
        .catch((error) => {
          enqueueSnackbar(getErrorMessage(error), {
            variant: "error",
          });
          setState({ data: [], lastPage: 1, page: 1, perPage: 10, total: 0 });
          resolve({
            data: [],
            page: 0,
            total: 0,
          });
        });
    });

  async function handleDecline(id) {
    try {
      setLoader({ state: true, message: "Rejecting Case..." });
      const response = await CaseService.declineCase(id);
      if (response) {
        enqueueSnackbar("Your have declined the invitation.", {
          variant: "success",
        });
        MTRef.current.onSearchChange("");
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      hideAlert();
      setCaseDescriptionModal({ state: false });
    }
  }

  async function handleAccept(caseObj) {
    setCaseDescriptionModal({ state: false });
    setModal({ state: true, case: caseObj });
  }

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case labels.home:
        navigate("/dashboard");
        break;
      default:
        break;
    }
  };

  return (
    <Fragment>
      <ActionBar {...{ breadcrumbs, onBreadcrumbClick }} />
      <Container className="CreateCase">
        <Heading>{labels.case_invites_received}</Heading>
        <TableWrapper>
          <CustomTable
            {...{
              columns,
              data,
              state,
              MTRef,
              placeholderText,
            }}
            // noToolbar
            hideFilterBar
            pageSize={10}
            singularTitle=""
            pluralTitle={labels.invites}
          />
        </TableWrapper>
      </Container>
      {modal.state && <AssignPartyModal {...{ modal, setModal, MTRef }} />}
      {caseDescriptionModal.state && (
        <CaseDescriptionModal
          {...{
            caseDescriptionModal,
            setCaseDescriptionModal,
            handleAccept,
            setAgentModal,
          }}
          handleDecline={() =>
            showAlert({
              heading: "Decline Case?",
              desc: "Are you sure you want to decline this case invite? This operation cannot be undone.",
              primaryBtnText: "Decline",
              secondaryBtnText: "Cancel",
              clickSecondarybtn: () => hideAlert(),
              clickPrimaryBtn: () => {
                handleDecline(caseDescriptionModal.caseDetails?.id);
              },
              isOpen: true,
              onDialogClose: () => hideAlert(),
            })
          }
        />
      )}
      {agentModal.state && (
        <AgentListModal
          modal={agentModal}
          setModal={setAgentModal}
          type="claimant"
        />
      )}
    </Fragment>
  );
};
export default Index;

export const Heading = styled.span`
  margin-bottom: 15px;
  font-family: ${theme.fonts.primaryFontExtraBold};
  font-size: 18px;
  color: ${COLORS.COLOR_DARK};
`;
