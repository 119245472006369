import styled from "styled-components";
import theme from "../../assets/theme";
import COLORS from "../../assets/Colors";

export const Container = styled.div`
  padding: 14px 43px;
  display: flex;
  flex-direction: column;
  & .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
`;
export const FilterBarContainer = styled.div`
  display: flex;
  position: fixed;
  height: 100vh;
  flex-direction: column;
  margin: auto;
  align-items: flex-start;
  width: 100%;
  font-size: 14px;
  text-align: left;
  padding: 20px 20px 100px;
  background-color: #fff;
  box-shadow: 5px 0 8px #00000015;
  overflow-y: auto;
  ${"" /* border-right: #acb1c2 solid 1px; */}
  z-index: 0;
  @media (max-width: 1024px) {
    width: 50%;
    z-index: 1;
    height: 100%;
  }
  @media (max-width: 700px) {
    width: 100%;
    z-index: 1;
    height: 100%;
  }
`;

export const SearchBarContainer = styled.div`
  display: flex;
  width: 80%;
  height: 38px;
  // margin: 10px 0;
  text-align: center;
  border: 2px solid ${COLORS.BORDER_GREY};
  border-radius: 5px;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const SearchBar = styled.input`
  width: 100%;
  padding: 10px 0;
  border: none;
  background-color: inherit;
  color: ${COLORS.COLOR_DARK};
  margin: auto 0 0 0;
  outline: none;
  font-size: 14px;
  font-weight: 600;
  font-family: ${theme.fonts.primaryFontRegular};
  &::placeholder {
    color: #acb1c2;
  }
`;

export const Grid = styled.div`
  display: colum;
  width: 100%;
  margin-bottom: 10px;
  @media ${theme?.breakpoints?.sm_up} {
    display: grid;
    grid-template-columns: 2.5fr 2.5fr 3fr 3fr 4fr;
    grid-column-gap: 15px;
  }
`;

export const SearchIcon = styled.div`
  width: 16px;
  height: 16px;
  margin: auto 2px;
`;

export const ContainerGrid = styled.div`
  margin-bottom: 45px;
  @media ${theme?.breakpoints?.sm_up} {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr 2fr;
    grid-column-gap: 15px;
  }
`;

export const Label = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 12px;
  line-height: 1.9;
  color: ${COLORS.INPUT_LABEL};
  text-transform: capitalize;
`;

export const Center = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: #454545;
  cursor: pointer;
`;

export const SelectContainer = styled.div`
  display: flex;
  & .agent_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 3px 0px;
    & .agent_name {
      font-size: 16px;
      font-weight: 600;
      flex: 1;
      color: ${COLORS.COLOR_DARK};
      font-family: ${theme.fonts.primaryFontSemiBold};
    }
    & .agent_email {
      font-size: 16px;
      font-weight: 600;
      flex: 1;
      color: ${COLORS.TRANSCRIPT_GRAY};
      font-family: ${theme.fonts.primaryFontSemiBold};
    }
    & .add_circle {
      margin: 0px 20px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 131, 140, 0.13);
      cursor: pointer;
      & .add_icon {
        font-size: 14px;
        color: ${COLORS.BTN_GREEN};
      }
    }
  }
`;

export const HyperLink = styled.span`
  cursor: pointer;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
`;

export const Loader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const PieIcon = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
  cursor: pointer;
  right: 30px;
  margin-top: 38px;
`;

export const Circle = styled.circle`
  cursor: pointer;
`;

export const Tooltip = styled.div`
  position: absolute;
  left: 65%;
  top: 24%;
  background-color: #000000;
  color: #ffffff;
  padding: 5px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  border-radius: 8px;
  pointer-events: none;
`;

export const StyledInput = styled.input`
  cursor: pointer;
  accent-color: ${({ checked }) => (checked ? "#00838c" : "#ffffff")};
`;
