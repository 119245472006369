import React, { useEffect } from "react";
import { Content, BlogPage } from "./Styles";
import { Header } from "../../internal/Header/Header";
import Footer from "../LandingPage/Footer";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";

/* eslint-disable jsx-a11y/anchor-is-valid */

function OnlineDisputeNineteen(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header>
        <BlogPage>
          <HeadWrap>Mediation Act, 2023</HeadWrap>
          <br />
          <p
            style={{ textAlign: "left", fontSize: "16px", fontStyle: "italic" }}
          >
            {" "}
            Authored by: Priyadharshini
          </p>
          <br />
          <p
            style={{ textAlign: "left", fontSize: "16px", fontStyle: "italic" }}
          >
            {" "}
            Edited by: Justice K. Kannan
          </p>
          <br />
          <Content>
            <SubHeadWrapHeading>1. Introduction:</SubHeadWrapHeading>
            <br />
            <p>
              In the labyrinth of legal battles, where adversarial swords clash,
              there exists a quieter path—a path illuminated by dialogue,
              empathy, and mutual understanding. It is not merely an alternative
              dispute resolution (ADR) tool to court litigation. The path of
              mediation as transcendental space to mainstream the act of finding
              answers to intransigent conflicts gets a shot in the arm through
              legislative initiative unbundled through the Mediation Act, 2023,
              that has received the assent of the President on 15<sup>th</sup>{" "}
              September 2023. The <b>Mediation Act, 2023</b> in India is a
              significant legislative step towards promoting and
              institutionalizing mediation as an effective dispute resolution
              mechanism. Here are the main features of this Act:
            </p>
            <br />
            <SubHeadWrapHeading>
              2. Promotion and Facilitation of Mediation:
            </SubHeadWrapHeading>
            <br />
            <p>
              The Act aims to <b>promote and facilitate mediation</b>,
              especially institutional mediation, for resolving disputes—whether
              commercial or otherwise. An institutional mediation means the
              mediation conducted under aegis of mediation service provider. The
              mediation service provider means:
            </p>
            <br />
            <div style={{ marginLeft: "40px" }}>
              <ol>
                <li>
                  Body or an organisation that provides for conduct of mediation
                  under the Act.
                </li>
                <li>
                  Authority constituted under Legal Services Authority Act.
                </li>
                <li>Court annexed mediation centres.</li>
                <li>Any other body notified by the Central Government.</li>
              </ol>
            </div>
            <br />
            <p>
              The court annexed mediation centres are invariably connected to
              the Legal Services Authority and from our experience, there is
              hardly any difference. If we must infuse a higher level of
              participation and make possible technology through online
              resolution acceptable to people, the maximum impetus must come
              from private bodies and organisations. The Act contemplates them
              to be institutions recognised by the mediation council. The
              mediation council itself has not been set up and therefore, for
              the time being, the institutions recognised by the Ministry of Law
              and Justice and organisations empaneled by the State Legal Service
              Authority, or the High Court shall be taken as the mediation
              service provider. The Act lays down the required qualifications of
              Mediators and establishes standards for their training and
              certification. Such provisions not only ensure the process is
              driven by skilled professionals with requisite expertise but also
              ensure conduct of proceedings in an ethical manner, while ensuring
              adherence to the provisions of the Act.
            </p>
            <br />
            <SubHeadWrapHeading>3. Scope and Applicability</SubHeadWrapHeading>
            <br />
            <p>
              This Act shall apply where mediation is conducted in India, and—
            </p>
            <p>
              (i) all or both parties habitually reside in or are incorporated
              in or have their place of business in India; or (ii) the mediation
              agreement provides that{" "}
              <span style={{ fontStyle: "italic" }}>any</span> dispute shall be
              resolved in accordance with the provisions of this Act; or
            </p>
            <p>
              (iii) there is an international mediation; or (iv) wherein one of
              the parties to the dispute is the Central Government or a State
              Government or agencies, public bodies, corporations and local
              bodies, including entities controlled or owned by such Government
              and where the matter pertains to a commercial dispute; or (v) to
              any other kind of dispute if deemed appropriate and notified by
              the Central Government or a State Government from time to time,
              for resolution through mediation under this Act, wherein such
              Governments, or agencies, public bodies, corporations and local
              bodies including entities controlled or owned by them, is a party.
              Non-commercial disputes involving government, say a service
              dispute cannot be a subject of mediation.
            </p>
            <br />
            <SubHeadWrapHeading>
              4. How does mediation get triggered?
            </SubHeadWrapHeading>
            <br />
            <p>
              If there is a mediation agreement between the parties or persons
              claiming through them that may include a clause in a contract or a
              separate agreement, signed by the parties or subject of
              communication through email or referred in pleadings in a suit or
              proceeding without a rebuttal from the other side. It may be
              agreed by parties even after disputes have arisen.
            </p>
            <p>
              Before filing any suit or proceedings of civil or commercial
              nature in any court, parties may voluntarily and with mutual
              consent take steps to settle the disputes by pre-litigation
              mediation a party by making a request to any person designated for
              this purpose by the High Courts, or an Authority constituted under
              the Legal Services Authorities Act, 1987 to undertake the
              mediation process
            </p>
            <br />
            <SubHeadWrapHeading>
              5. Enforcement of Mediated Settlement Agreements:
            </SubHeadWrapHeading>
            <br />
            <p>
              The Act provides a mechanism for enforcing mediated settlement
              agreements. When parties reach an agreement through mediation, it
              becomes legally binding and enforceable as if it were a decree. In
              any case in which the mediated settlement agreement is arrived at
              between the parties is sought to be challenged by either of the
              parties, such party may file an application before the court or
              tribunal of competent jurisdiction on the following grounds: (i)
              fraud; (ii) corruption; (iii) impersonation; or (iv) where the
              mediation was conducted in disputes or matters not fit for
              mediation, viz., disputes involving prosecution for criminal
              offences; matters pertaining to NGT, land acquisition by
              government, etc as set out in Sch I to the Act.
            </p>
            <br />
            <SubHeadWrapHeading>6. Process of mediation</SubHeadWrapHeading>
            <br />
            <p>
              Provisions of the Act give regard to confidentiality of
              proceedings, which is the lifeblood of mediation. Such emphasis on
              confidentiality helps increase the trust and confidence of the
              parties on the Mediator, thereby increasing efficiency of the
              process and chances of success. The provisions of the Act strictly
              prohibit the disclosure of any information sought during the
              conduct of Mediation and such information will not be admissible
              as evidence in any subsequent legal proceedings. The Act
              acknowledges the importance of technology and makes{" "}
              <b>online mediation</b>an acceptable and cost-effective process.
              Audio/video recording of the proceedings is strictly prohibited.
              The provisions of confidentiality are applicable not only to the
              parties but are also extended to Mediators and other third parties
              such as experts or other administrators who may be directly or
              indirectly involved in the process. In cases where the Mediator is
              of an opinion that the dispute cannot be resolved or the parties
              are unable to come to agreement, the Mediator shall submit a
              non-settlement report to the parties (in case of ad-hoc Mediation)
              or the Mediation service provider (in case of institutional
              Mediation). Such a report shall be bound by the provisions of
              confidentiality contained in the Act.
            </p>
            <br />
            <p>
              The Act contains specific provisions regarding the appointment and
              role of Mediators. The parties to the dispute have complete
              autonomy with respect to the appointment of Mediators. The parties
              may also agree on the procedure for appointment of the Mediator.
              In any case where the parties are unable to agree on a Mediator,
              they are free to approach a Mediation service provider for such
              appointment. Upon appointment the Mediator shall disclose any
              conflict of interest. This helps ensure the independence and
              impartiality of the Mediator and their ability to settle the
              dispute in a fair and unbiased manner.
            </p>
            <br />
            <SubHeadWrapHeading>7. Community Mediation:</SubHeadWrapHeading>
            <br />
            <p>
              The Act encourages <b>community mediation</b>, recognizing that
              disputes can be resolved effectively within local communities.
              Community mediators play a crucial role in resolving neighborhood,
              family, and community-level conflicts. The Authority constituted
              under Legal Services Authority Act or District Magistrate or the
              Sub-Divisional Magistrate, as the case may be, shall notify a
              permanent panel of community mediators, who may be (a) persons of
              standing and integrity who are respectable in the community; (b)
              any local person whose contribution to the society has been
              recognised; (c) representative of area or resident welfare
              associations; (d) person having experience in the field of
              mediation; and (e) any other person deemed appropriate. However,
              any settlement agreement arrived at for the purpose of maintaining
              peace, harmony and tranquillity amongst the residents or families
              of any area or locality but shall not be enforceable as a judgment
              or decree of a civil court.
            </p>
            <br />
            <SubHeadWrapHeading>8. Miscellaneous:</SubHeadWrapHeading>
            <br />
            <p>
              Mediation is a time-efficient process compared to lengthy court
              proceedings and mandates the mediation to be concluded within 120
              days from the date of first appearance before the mediator or from
              the date of appointment of mediation, where there is no mediation
              agreement, subject however to extension of time by 60 days. It
              reduces the burden on the judicial system and promotes quicker
              resolution. The Act defines the territorial jurisdiction within
              which mediation can take place. The Act establishes a Mediation
              Fund to support mediation initiatives. It addresses the cost of
              mediation, making it accessible to all. The provisions of this Act
              override any conflicting provisions related to mediation or
              conciliation in other laws.
            </p>
            <br />
            <SubHeadWrapHeading>9. Conclusion:</SubHeadWrapHeading>
            <br />
            <p>
              With its focus on confidentiality, impartiality, and the
              empowerment of parties involved, this legislation sets a new
              standard for effective conflict resolution. As we navigate the
              legal landscape, the Mediation Act stands as a beacon, guiding us
              towards a more collaborative and harmonious future. Mediation is
              not a footnote; it’s a symphony. Let us elevate it from the
              margins to the mainstream. Let us dance to its rhythm—a rhythm
              that harmonizes justice, compassion, and human connection.
            </p>
          </Content>
          <br />
          <Center>
            <p
              style={{
                fontStyle: "italic",
              }}
            >
              The legal world awaits its maestros—mediators who wield empathy as
              skillfully as statutes.
            </p>
          </Center>
        </BlogPage>
      </Header>
      <Footer />
    </>
  );
}

export default OnlineDisputeNineteen;

export const ContentExample = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 14px;
`;

export const Block = styled.div`
  width: 183px;
  height: 0;
  border: 1px solid ${COLORS.INPUT_BLACK};
  margin: 3px;
  display: inline-block;
  margin-bottom: 12px;
  font-family: ${theme.fonts.primaryFontRegular};
`;
export const HeadWrap = styled.div`
  text-align: center;
  font-size: 18px;
  color: ${COLORS.BTN_GREEN};
  font-family: ${theme.fonts.primaryFontBold};
  @media ${theme?.breakpoints?.sm_up} {
    font-size: 25px;
  }
`;

export const SubHeadWrapHeading = styled.div`
  text-align: left;
  font-size: 20px;
  padding-left: 1.5%;
  color: ${COLORS.BTN_GREEN};
  font-family: ${theme.fonts.primaryFontRegular};
`;

const Center = styled.div`
  display: flex;
  font-size: 16px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
