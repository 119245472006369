import React, { useEffect } from "react";
import { Content, SubHeadWrap, BlogPage } from "./Styles";
import { Header } from "../../internal/Header/Header";
import Footer from "../LandingPage/Footer";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";

/* eslint-disable jsx-a11y/anchor-is-valid */

function LimitationOfArbitration(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header>
        <BlogPage>
          <HeadWrap>LIMITATION IN ARBITRATION Part II</HeadWrap>
          <br />
          <br />
          <Content>
            <SubHeadWrap style={{ marginBottom: "10px", marginTop: "10px" }}>
              Part II
            </SubHeadWrap>
            <p>
              This article will further discuss the shortcomings of the
              application of limitation in arbitration proceedings through the
              following entry points:
            </p>
            <div style={{ marginLeft: "40px", marginTop: "12px" }}>
              <p style={{ marginBottom: "10px" }}>
                ● Limitation period for the initiation of arbitration
                proceedings.
              </p>
              <p style={{ marginBottom: "12px" }}>
                ● Cause of action that activates the limitation period.
              </p>
            </div>
            <p>
              Previously, the Supreme Court in{" "}
              <span style={{ fontStyle: "italic" }}>
                Wazir Chand Mahajan v. Union of India<sup>1</sup>
              </span>{" "}
              held that Article 137 of the First Schedule to the Limitation Act,
              1963 would apply to petitions moved under the Arbitration &amp;
              Conciliation Act, 1996, which was upheld in{" "}
              <span style={{ fontStyle: "italic" }}>
                Inder Singh Rekhi v. DDA<sup>2</sup>.
              </span>
            </p>
            <br />
            <p>
              In{" "}
              <span style={{ fontStyle: "italic" }}>
                Satender Kumar v. MCD<sup>3</sup>
              </span>{" "}
              , the Delhi High Court while affirming prior precedents in the
              matter held that limitation commences when the cause of action
              accrues/ arises and accrual/ arising of cause of action
              necessarily varies as per facts and circumstances of each case and
              the nature of jural relationship between the parties viz.,
              contractual or otherwise.
            </p>
            <SubHeadWrap style={{ marginBottom: "10px", marginTop: "10px" }}>
              Cause of Action
            </SubHeadWrap>
            <SubHeadWrap style={{ marginBottom: "10px", fontSize: "18px" }}>
              What constitutes the cause of action and the date of occurrence
              from which the limitation period should be computed?
            </SubHeadWrap>
            <p>
              In an earlier ruling in{" "}
              <span style={{ fontStyle: "italic" }}>
                Union of India v. L. K. Ahuja and Co.<sup>4</sup>
              </span>{" "}
              , it was held that the first aspect, i.e., the limitation for the
              filing of a petition seeking reference of the disputes to
              arbitration is different from the period of limitation for the
              subject claims. The petition could be within limitation because it
              may be filed within 3 years of arising of disputes. However,
              whether the main claims are time barred or not is an issue on
              merits to be decided in the arbitration proceedings. The second
              and the more important aspect is, if on the admitted facts, the
              claims are clearly barred by limitation at the time of passing of
              the order under Section 20 of the Arbitration Act, 1940, then,
              there need not be reference of the disputes to arbitration because
              there is no entitlement to money, and therefore, no dispute or
              difference with respect to the same can stand, once the same are
              clearly time barred.
            </p>
            <br />
            <p>
              Even though the above case law discusses the Arbitration Act,
              1940, the principle underlying the application of limitation is
              important to note.
            </p>
            <br />
            <p>
              Recent judgments including{" "}
              <span style={{ fontStyle: "italic" }}>
                Progressive Construction Limited v. National Hydroelectric Power
                Corp Ltd.<sup>5</sup>
              </span>{" "}
              depict the changing position of the law on limitation where it was
              held that claims which are ex facie barred by limitation need not
              be referred for decision in the arbitration proceedings.
            </p>
            <br />
            <p>
              The Supreme Court in{" "}
              <span style={{ fontStyle: "italic" }}>
                Geo Miller v. Rajasthan Vidyut Nigam<sup>6</sup>
              </span>{" "}
              , while relying on{" "}
              <span style={{ fontStyle: "italic" }}>
                Shree Ram Mills Ltd. v. Utility Premises (P) Ltd.<sup>7</sup>
              </span>{" "}
              , observed that –
            </p>
            <br />
            <p style={{ fontStyle: "italic" }}>
              “The Court upon careful consideration of such history must find
              out what was the ‘breaking point’ at which any reasonable party
              would have abandoned efforts at arriving at a
            </p>
            <br />
            <p style={{ fontStyle: "italic" }}>
              settlement and contemplated referral of the dispute for
              arbitration. This ‘breaking point’ would then be treated as the
              date on which the cause of action arises, for the purpose of
              limitation.”
            </p>
            <br />
            <p style={{ fontStyle: "italic" }}>
              “Moreover, in a commercial dispute, while mere failure to pay may
              not give rise to a cause of action, once the applicant has
              asserted their claim and the respondent fails to respond to such
              claim, such failure will be treated as a denial of the applicant’s
              claim giving rise to a dispute, and therefore the cause of action
              for reference to arbitration.”
            </p>
            <br />
            <p>
              The Court also discussed{" "}
              <span style={{ fontStyle: "italic" }}>“undue hardship”</span> as
              provided for under Section 43 of the Arbitration &amp;
              Conciliation Act, 1996, the conduct of the appellant in{" "}
              <span style={{ fontStyle: "italic" }}>
                “sleeping over his rights”
              </span>{" "}
              and lack of{" "}
              <span style={{ fontStyle: "italic" }}>“sufficient cause”</span>{" "}
              for condonation of delay under Section 5 of the Limitation Act,
              1963, as contributing factors to determine the extension of time.
            </p>
            <SubHeadWrap style={{ marginBottom: "10px", marginTop: "10px" }}>
              Conclusion
            </SubHeadWrap>
            <p>
              The judgments discussed within the article frame the changing
              contours of the legal position on limitation in arbitration while
              underlining the gaps in the existing law. They also provide for
              distinctions between the rules of limitation that apply to
              applications vis a vis those that apply to substantive claims.
            </p>
            <Block />
            <p>
              <sup>1</sup> Wazir Chand Mahajan v. Union of India, (1967) 1 SCR
              303.
            </p>
            <p>
              <sup>2</sup> Inder Singh Rekhi v. DDA, (1988) 2 SCC 338.
            </p>
            <p>
              <sup>3</sup> Satender Kumar v. MCD, Arb. P. No. 253/2009.
            </p>
            <p>
              <sup>4</sup> Union of India v. L. K. Ahuja and Co., (1988) 3 SCC
              76.
            </p>
            <p>
              <sup>5</sup> Progressive Construction Limited v. National
              Hydroelectric Power Corp Ltd., MANU/DE/1293/2009.
            </p>
            <p>
              <sup>6</sup> Geo Miller v. Rajasthan Vidyut Nigam, Civil Appeals
              No. 968 and 969 of 2010.
            </p>
            <p>
              <sup>7</sup> Shree Ram Mills Ltd. v. Utility Premises (P) Ltd.,
              (2007) 4 SCC 599.
            </p>
          </Content>
        </BlogPage>
      </Header>
      <Footer />
    </>
  );
}

export default LimitationOfArbitration;

export const ContentExample = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 14px;
`;

export const Block = styled.div`
  width: 193px;
  height: 0;
  border: 1px solid ${COLORS.INPUT_BLACK};
  margin: 3px;
  display: inline-block;
  margin-top: 12px;
  margin-bottom: 12px;
  font-family: ${theme.fonts.primaryFontRegular};
`;

export const HeadWrap = styled.div`
  text-align: center;
  font-size: 18px;
  color: ${COLORS.BTN_GREEN};
  font-family: ${theme.fonts.primaryFontBold};
  @media ${theme?.breakpoints?.sm_up} {
    font-size: 25px;
  }
`;

export const SubHeadWrapHeading = styled.div`
  text-align: left;
  font-size: 20px;
  padding-left: 1.5%;
  color: ${COLORS.BTN_GREEN};
  font-family: ${theme.fonts.primaryFontRegular};
`;

export const TextLinesData = styled.div`
  padding-left: 30px;
  margin-left: 30px;
  text-align: justify;
`;
