import React from "react";
import Drawer from "@material-ui/core/Drawer";
import styled from "styled-components";
import COLORS from "../../../../assets/Colors";
import theme from "../../../../assets/theme";
import {
  PrimaryCTAButton,
  PrimaryOutlinedCTAButton,
} from "../../../common/Buttons";
import {
  formatNumberToMetrics,
  parseTimeStamp,
} from "../../../../helpers/functions";
import moment from "moment";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from "@material-ui/core";
import CustomTable from "../../../common/CustomTable/CustomTable";
import { Fragment } from "react";
import { navigate } from "@reach/router";

export default function CaseDescriptionModal({
  caseDescriptionModal = { state: false },
  handleDecline = () => {},
  handleAccept = () => {},
  setCaseDescriptionModal = () => {},
  setAgentModal = () => {},
}) {
  const { caseDetails } = caseDescriptionModal || {};
  function renderClaimValue(rowData) {
    const caseItems = rowData.totalClaimValue;
    const currencyUnit = rowData?.currencyUnit;
    if (caseItems)
      return (
        currencyUnit +
        " " +
        formatNumberToMetrics(parseFloat(caseItems).toFixed(2), "INR")
      );
    else return "Non Monetary";
  }
  return (
    <Drawer
      anchor={"right"}
      open={caseDescriptionModal.state}
      onClose={() => setCaseDescriptionModal({ state: false })}
    >
      <DrawerContainer role="presentation">
        <HeadingContainer>
          <Heading>{caseDetails?.title}</Heading>
          <CloseModal
            onClick={() => setCaseDescriptionModal({ state: false })}
            src={require("../../../../assets/images/closeModal.svg")}
          />
        </HeadingContainer>
        <AgentFormContainer>
          <Grid>
            <GridItem>
              <Label>Case ID</Label>
              <Value>{caseDetails?.id}</Value>
            </GridItem>
            <GridItem>
              <Label>Monetary Claim Value</Label>
              <Value>{renderClaimValue(caseDetails)}</Value>
            </GridItem>
            <GridItem>
              <Label>Filed On</Label>
              <Value>
                {moment(
                  caseDetails.submittedOn ||
                    parseTimeStamp(caseDetails.created_at)
                ).format("DD/MM/YYYY")}
              </Value>
            </GridItem>

            {caseDetails?.respondentParties?.length ? (
              <>
                {caseDetails?.respondentParties?.map((el, index) => (
                  <Fragment key={index}>
                    <GridItem>
                      <Label>Respondent</Label>
                      <Value>{el?.name || "-"}</Value>
                    </GridItem>
                    <GridItem>
                      <Label>Respondent Email</Label>
                      <Value>{el?.email || "-"}</Value>
                    </GridItem>
                    <GridItem>
                      <Label>Respondent Phone</Label>
                      <Value>{el?.mobile || "-"}</Value>
                    </GridItem>
                  </Fragment>
                ))}
              </>
            ) : (
              <>
                <GridItem>
                  <Label>Respondent</Label>
                  <Value>
                    {caseDetails?.respondentName ||
                      caseDetails?.respondentParty?.name ||
                      (caseDetails?.respondentParties?.length &&
                        caseDetails?.respondentParties[0]?.name) ||
                      "-"}
                  </Value>
                </GridItem>
                <GridItem>
                  <Label>Respondent Email</Label>
                  <Value>
                    {caseDetails?.respondentEmail ||
                      (caseDetails?.respondentParties?.length &&
                        caseDetails?.respondentParties[0]?.email) ||
                      "-"}
                  </Value>
                </GridItem>
                <GridItem>
                  <Label>Respondent Phone</Label>
                  <Value>
                    {caseDetails?.respondentMobile ||
                      (caseDetails?.respondentParties?.length &&
                        caseDetails?.respondentParties[0]?.mobile) ||
                      "-"}
                  </Value>
                </GridItem>
              </>
            )}
          </Grid>
          <div style={{ marginTop: 40, marginBottom: 40 }}>
            <Label>Description</Label>
            <Value style={{ textAlign: "justify" }}>
              {caseDetails?.description}
            </Value>
          </div>
          <AccordionContainer>
            <ExpansionPanel
              classes={{
                root: "accordion-root",
              }}
            >
              <ExpansionPanelSummary
                expandIcon={
                  <img
                    style={{ transform: "rotate(180deg)" }}
                    src={require("../../../../assets/images/dropdownIcon2.svg")}
                    alt="expand"
                  />
                }
                aria-controls="panel3a-content"
                id="panel3a-header"
                classes={{
                  content: "accordion-content",
                  expandIcon: "accordion-icon",
                }}
              >
                <AccordionHeading>Claimants Agents</AccordionHeading>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <CustomTable
                  columns={[
                    {
                      // field: "user.name",
                      sorting: false,
                      title: "Agent Name",
                      headerStyle: {
                        fontSize: 12,
                      },
                      render: (rowData) => (
                        <span
                          style={{
                            fontFamily: theme.fonts.primaryFontBold,
                            fontSize: 12,
                          }}
                        >
                          {rowData?.user?.name}
                        </span>
                      ),
                    },
                    {
                      field: "email",
                      sorting: false,
                      title: "Email",
                      headerStyle: {
                        fontSize: 12,
                      },
                      cellStyle: {
                        fontSize: 12,
                      },
                      render: (rowData) => (
                        <a href={`mailto:${rowData.email}`}>{rowData.email}</a>
                      ),
                    },
                    {
                      field: "user.mobile",
                      sorting: false,
                      title: "Phone",
                      headerStyle: {
                        fontSize: 12,
                      },
                      cellStyle: {
                        fontSize: 12,
                      },
                      render: (rowData) => <>{rowData?.user?.mobile}</>,
                    },
                  ]}
                  hidePagination
                  hideSearch
                  noToolbar
                  singularTitle=""
                  pluralTitle={"agents"}
                  state={{
                    data: caseDetails?.claimantParty?.agents,
                  }}
                  data={caseDetails?.claimantParty?.agents}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </AccordionContainer>
        </AgentFormContainer>
        <Flex>
          <PrimaryOutlinedCTAButton
            type="button"
            onClick={handleDecline}
            style={{
              width: "47%",
            }}
          >
            Decline Case
          </PrimaryOutlinedCTAButton>
          <PrimaryCTAButton
            onClick={() =>
              caseDetails.resolutionKind === "arbitration"
                ? navigate(`cases/accept/${caseDetails.id}?caseType=arbitration`, {
                    state: caseDetails,
                  })
                : handleAccept(caseDetails)
            }
            style={{
              width: "47%",
            }}
          >
            Accept Case
          </PrimaryCTAButton>
        </Flex>
      </DrawerContainer>
    </Drawer>
  );
}

const DrawerContainer = styled.div`
  width: 100vw;
  position: relative;
  height: 100vh;
  overflow: hidden;
  padding-bottom: 208px;
  @media ${theme?.breakpoints?.sm_up} {
    width: 600px;
  }
`;

const AccordionContainer = styled.div`
  & .accordion-root {
    box-shadow: none;
    border-radius: 4px;
    border: solid 0.5px #e1e3ee;
    background-color: #ffffff;
  }
  & .accordion-content {
    margin: 0;
    padding: 26px 24px;
  }
  & .accordion-icon {
    margin-right: 5px;
  }
  & .custom-table {
    width: 100%;
  }
`;

const AccordionHeading = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  color: #293461;
`;

const HeadingContainer = styled.div`
  padding-top: 35px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 54px;
  border-bottom: 1px solid ${COLORS.INPUT_BORDER};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const AgentFormContainer = styled.div`
  height: 100%;
  padding-top: 26px;
  padding-left: 38px;
  padding-right: 38px;
  padding-bottom: 26px;
  overflow-y: scroll;

  & form {
    padding-top: 10px;
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  position: absolute;
  bottom: 60px;
  width: calc(100% - 76px);
  left: 50%;
  transform: translateX(-50%);
`;

const Grid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media ${theme?.breakpoints?.sm_up} {
    display: grid;
    grid-template-columns: 1.5fr 2fr 2fr;
    width: 100%;
    grid-row-gap: 40px;
    grid-column-gap: 25px;
  }
`;

const GridItem = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-right: 30px;
  &:nth-child(1),
  &:nth-child(2) {
    margin-top: 0;
  }
  justify-content: center;
  @media ${theme?.breakpoints?.sm_up} {
    margin-top: 0;
    padding-right: 0;
    width: auto;
  }
`;

const Value = styled.div`
  font-size: 14px;
  word-break: break-word;
  font-family: ${theme.fonts.primaryFontSemiBold};
  color: ${COLORS.COLOR_DARK};
  margin-top: 2px;
`;

const Label = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 12px;
  line-height: 1.7;
  color: ${COLORS.INPUT_LABEL};
`;
