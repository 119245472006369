import React, { useEffect } from "react";
import { Content, SubHeadWrap, BlogPage } from "./Styles";
import { Header } from "../../internal/Header/Header";
import Footer from "../LandingPage/Footer";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";

/* eslint-disable jsx-a11y/anchor-is-valid */

function NonArbitrableCases(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header>
        <BlogPage>
          <HeadWrap>NON-ARBITRABLE CASES</HeadWrap>
          <br />
          <br />
          <Content>
            <SubHeadWrap style={{ marginBottom: "10px" }}>Part II</SubHeadWrap>
            <SubHeadWrap style={{ marginBottom: "10px" }}>
              Booz Allen & Hamilton Inc v SBI Home Finance Ltd
            </SubHeadWrap>
            <p>
              The case is foundational to the development of the parameters of
              non-arbitrability of disputes and the Supreme Court laid down
              general rules in this regard while adjudicating on whether an
              action for the enforcement of a mortgage is arbitrable.
            </p>
            <br />
            <p>
              The Court discussed the three facets of arbitrability relating to
              the jurisdiction of the arbitral tribunal herein:
            </p>
            <br />
            <div style={{ marginLeft: "40px" }}>
              <p style={{ marginBottom: 5 }}>
                (i) Whether the disputes are capable of adjudication and
                settlement by arbitration?
              </p>
              <p style={{ marginBottom: 5 }}>
                (ii) Whether the disputes are covered by the arbitration
                agreement?
              </p>
              <p style={{ marginBottom: 5 }}>
                (iii) Whether the parties have referred the disputes to
                arbitration?
              </p>
            </div>
            <br />
            <p>
              The Court also pointed out that adjudication in certain categories
              of cases were reserved for public fora exclusively as a matter of
              public policy. Other categories of proceedings, though not
              reserved exclusively for adjudication by public fora including
              courts and tribunals, may, by necessary implication stand excluded
              from the ambit of private fora.
            </p>
            <br />
            <p>
              Furthermore, the Court added, subordinate rights in personam,
              arising from rights in rem have always been considered to be
              arbitrable and hence may form one of the exceptions to the above.
              Therefore, the rule is not rigid or inflexible since there is
              scope for certain matters to be arbitrable even if broadly under
              the umbrella of rights in rem.
            </p>
            <br />
            <p>
              The class of cases that cannot be arbitrated, as cited by the
              court in this case, includes -
            </p>
            <br />
            <div>
              <p style={{ marginBottom: 5 }}>
                (i) Disputes relating to rights and liabilities which give rise
                to or arise out of criminal offences
              </p>
              <p style={{ marginBottom: 5 }}>
                (ii) Matrimonial disputes relating to divorce, judicial
                separation, restitution of conjugal rights, child custody
              </p>
              <p style={{ marginBottom: 5 }}>(iii) Guardianship matters</p>
              <p style={{ marginBottom: 5 }}>
                (iv) Insolvency and winding up matters
              </p>
              <p style={{ marginBottom: 5 }}>
                (v) Testamentary matters (grant of probate, letters of
                administration and succession certificate) and
              </p>
              <p style={{ marginBottom: 5 }}>
                (vi) Eviction or tenancy matters governed by special statutes
              </p>
            </div>
            <br />
            <SubHeadWrap>
              Vidya Drolia and others v Durga Trading Corporation
            </SubHeadWrap>
            <br />
            <p>
              In Vidya Drolia, the arbitrability (or not) of subject matter and
              who the decision-maker (whether Court or arbitral tribunal) would
              be, regarding the question of arbitrability, formed the crux of
              the final determination.
            </p>
            <br />
            <p>
              J. N.V. Ramana, in agreement with J. Sanjiv Khanna, in regard to
              the arbitrability of subject matter proposed a four-fold test to
              determine the above question:
            </p>
            <br />
            <TextLinesData>
              <ol>
                <li style={{ marginBottom: 10 }}>
                  <p>
                    <span>
                      When the cause of action and the subject matter of the
                      dispute relates to actions in rem, that do not pertain to
                      subordinate rights in personam that arise from rights in
                      rem
                    </span>
                  </p>
                </li>
                <li style={{ marginBottom: 10 }}>
                  <p>
                    <span>
                      When the cause of action and the subject matter of the
                      dispute affects third party rights; have erga omnes
                      effect; require centralised adjudication, and mutual
                      adjudication would not be appropriate and enforceable
                    </span>
                  </p>
                </li>
                <li style={{ marginBottom: 10 }}>
                  <p>
                    <span>
                      When the cause of action and the subject matter of the
                      dispute relates to inalienable sovereign and public
                      interest functions of the state and hence mutual
                      adjudication would be unenforceable and
                    </span>
                  </p>
                </li>
                <li style={{ marginBottom: 10 }}>
                  <p>
                    <span>
                      When the subject matter of the dispute is expressly or by
                      necessary implication non-arbitrable as per mandatory
                      statute(s)
                    </span>
                  </p>
                </li>
              </ol>
            </TextLinesData>
            <br />
            <p>
              In regard to ‘who decides’, the judges held that Sections 8 and 11
              of the A&C Act, 1996 delineate that the scope of judicial review
              and the jurisdiction of the court is identical i.e. extremely
              limited and restricted. The restricted and limited review is to
              check and protect parties from being forced to arbitrate when the
              matter is demonstrably ‘non-arbitrable’ and to ‘cut off the
              deadwood’.
            </p>
            <br />
            <p>
              It was reiterated that the Court may interfere at the Section 8 or
              11 stage as little as possible, and only when it is manifestly and
              ex facie certain that the arbitration agreement is non- existent,
              invalid or the disputes are non-arbitrable, though the nature and
              facets of non-arbitrability would, to some extent, determine the
              level and nature of judicial scrutiny. Building on the above
              formulation, the Court observed that the subject matter
              arbitrability cannot be decided at the stage of Sections 8 or 11
              of the Act, unless it is a clear case of deadwood.
            </p>
            <br />
            <p>
              It also defined the scope of the Court to examine the prima facie
              validity of an arbitration agreement and held that its inclusion
              in the determination extended to:
            </p>
            <br />
            <TextLinesData>
              <p style={{ marginBottom: 10 }}>
                a. Whether the arbitration agreement was in writing?
              </p>
              <p style={{ marginBottom: 10 }}>
                b. Whether the arbitration agreement was contained in exchange
                of letters, telecommunication etc?
              </p>
              <p style={{ marginBottom: 10 }}>
                c. Whether the core contractual ingredients of the arbitration
                agreement were fulfilled?
              </p>
              <p style={{ marginBottom: 10 }}>
                d. On rare occasions, whether the subject ­matter of dispute is
                arbitrable?
              </p>
              <br />
            </TextLinesData>
            <SubHeadWrap>In conclusion</SubHeadWrap>
            <br />
            <p>
              Taking into consideration the aforementioned aspects, the Apex
              Court provided some clarity with respect to the categories of
              cases that are non arbitrable and offered suitable reasons for the
              categorisation. Even though there are many lacunae to account for,
              including the specific class of cases with a criminal character
              that may be arbitrable, this would depend on the Supreme Court’s
              future framing of the limits on arbitrability and the inherent
              nature of the rights, liabilities, duties and obligations provided
              for under the existing regime of law.
            </p>
          </Content>
        </BlogPage>
      </Header>
      <Footer />
    </>
  );
}

export default NonArbitrableCases;

export const ContentExample = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 14px;
`;

export const Block = styled.div`
  width: 153px;
  height: 0;
  border: 1px solid ${COLORS.INPUT_BLACK};
  margin: 3px;
  display: inline-block;
  margin-bottom: 12px;
  font-family: ${theme.fonts.primaryFontRegular};
`;
export const HeadWrap = styled.div`
  text-align: center;
  font-size: 18px;
  color: ${COLORS.BTN_GREEN};
  font-family: ${theme.fonts.primaryFontBold};
  @media ${theme?.breakpoints?.sm_up} {
    font-size: 25px;
  }
`;

export const SubHeadWrapHeading = styled.div`
  text-align: left;
  font-size: 20px;
  padding-left: 1.5%;
  color: ${COLORS.BTN_GREEN};
  font-family: ${theme.fonts.primaryFontRegular};
`;

export const TextLinesData = styled.div`
  padding-left: 30px;
  margin-left: 30px;
  text-align: justify;
`;
