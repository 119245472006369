import React from "react";
import {
  AWAITING_RESPONDANT,
  RESPONDANT_ONBOARDED,
  NEGOTIATION_ONGOING,
  NEGOTIATION_REACHED,
  NEGOTIATION_QUIT_BY_CLAIMENT,
  NEGOTIATION_QUIT_BY_RESPONDENT,
  CLAIMENT_PAID_NEGOTIATION,
  BOTH_PAID_NEGOTIATION,
  RESPONDENT_PAID_NEGOTIATION,
  NEGOTIATION_QUIT_BY_CASEMANAGER,
} from "../../../../helpers/constants";
import CommentBox from "../common/CommentBox";
import CaseAgreement from "../common/CaseAgreement";
import AgreementDocCard from "../common/AgreementDocCard";
import { Spacing } from "../styles";
import _ from "lodash";

function MediationState({
  currentState,
  isRespondent,
  ownerId,
  caseDetails,
  showCta,
  comments,
  setComments,
  partyList,
  isLocalTranscriptionServer,
}) {
  /**
   * @description This function is to decide whether the respondent view is shown are not
   * @param rounds
   */

  const RenderCommentBox = ({
    status,
    expand,
    errorText,
    comments,
    caseId,
    setComments,
    disabled,
    isLocalTranscriptionServer,
  }) => {
    return (
      <CommentBox
        title={`${_.capitalize(caseDetails?.resolutionKind)} Process`}
        expand={expand}
        status={status}
        errorText={errorText}
        ownerId={ownerId}
        caseId={caseId}
        comments={comments}
        disabled={disabled}
        setComments={setComments}
        isRespondent={isRespondent}
        hasCaseManager={!_.isEmpty(caseDetails?.caseManager)}
        hasMediator={
          caseDetails?.requestedMediators?.length &&
          caseDetails?.requestedMediators?.some((el) => el?.id)
        }
        hideAddComment={
          currentState?.key === "awaitingRespondentAcceptance" ||
          currentState?.key === "noticeToArbitrate" ||
          currentState?.key === "arbitrationReferenced" ||
          currentState?.key === "arbitrationNominated" ||
          currentState?.key === "arbitrationConfirmed" ||
          currentState?.key === "waitingForArbitratorConfirmation" ||
          currentState?.key === "firstNoticeSent" ||
          currentState?.key === "statementOfClaimFiled" ||
          currentState?.key === "awaitingForSec17Details" ||
          currentState?.key === "sec17PetitionFiled" ||
          currentState?.key === "sec17Ordered" ||
          currentState?.key === "awaitingSec17Execution" ||
          currentState?.key === "sec17ExtensionMemoFiled" ||
          currentState?.key === "sec17ExtensionMemoOrdered" ||
          currentState?.key === "fsOrdered" ||
          currentState?.key === "attachmentOrdered" ||
          currentState?.key === "garnisheeProhibitaryOrder" ||
          currentState?.key === "garnisheeFinalOrder" ||
          currentState?.key === "sec17Closed" ||
          currentState?.key === "statementOfDefenceFiled" ||
          currentState?.key === "rejoinderFromClaimant" ||
          currentState?.key === "surrejoinderFromRespondent" ||
          currentState?.key === "2ndNoticeMOM" ||
          currentState?.key === "evidenceFiledByClaimant" ||
          currentState?.key === "evidenceFiledByRespondent" ||
          currentState?.key === "crossExamination" ||
          currentState?.key === "argumentsByClaimant" ||
          currentState?.key === "argumentsByRespondent" ||
          currentState?.key === "negotiationOngoing"
        }
        partyList={partyList}
        isLocalTranscriptionServer={isLocalTranscriptionServer}
        kind={
          caseDetails?.resolutionKind === "mediation"
            ? "Mediator"
            : "Arbitrator"
        }
      />
    );
  };

  const AgreementSection = () => {
    return (
      <>
        <Spacing>
          <CaseAgreement
            title={`Final Award Summary for Case ID: ${caseDetails?.id}`}
            message={caseDetails?.negotiationSummary}
            summaryStatus={caseDetails?.summaryStatus === "completed"}
            isPaid={currentState?.cta?.length === 0}
          />
        </Spacing>
        <Spacing>
          <AgreementDocCard
            id={caseDetails?.id}
            status={caseDetails.status}
            isRespondent={isRespondent}
            hasRating={caseDetails?.rating}
            hasAgreement={caseDetails?.hasAgreementDocument}
            type={`Download`}
          />
        </Spacing>
      </>
    );
  };

  switch (currentState?.key) {
    case AWAITING_RESPONDANT:
      return (
        <Spacing>
          <RenderCommentBox
            expand={true}
            caseId={caseDetails?.id}
            errorText={"Respondent Delined the Case"}
            status={caseDetails.respondentStatus === "declined" && "error"}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
            // disabled={true}
          />
        </Spacing>
      );
    case NEGOTIATION_QUIT_BY_CASEMANAGER: // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            status={"error"}
            errorText={"Case Closed by Case Manager"}
            comments={comments}
            setComments={setComments}
            disabled={true}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </div>
      );
    case RESPONDANT_ONBOARDED:
      return (
        <Spacing>
          <RenderCommentBox
            expand={true}
            caseId={caseDetails?.id}
            errorText={"Respondent Delined the Case"}
            status={caseDetails.respondentStatus === "declined" && "error"}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case NEGOTIATION_ONGOING: // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case NEGOTIATION_QUIT_BY_CLAIMENT: // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            status={"error"}
            errorText={"Case Quit by Claimant"}
            comments={comments}
            setComments={setComments}
            disabled={true}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case NEGOTIATION_QUIT_BY_RESPONDENT: // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            status={"error"}
            errorText={"Case Quit by Respondent"}
            comments={comments}
            setComments={setComments}
            disabled={true}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case NEGOTIATION_REACHED:
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            disabled={true}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case CLAIMENT_PAID_NEGOTIATION:
      return (
        <>
          <Spacing>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={false}
              comments={comments}
              setComments={setComments}
              disabled={true}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </Spacing>
          <AgreementSection />
        </>
      );
    case RESPONDENT_PAID_NEGOTIATION:
      return (
        <>
          <Spacing>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={false}
              comments={comments}
              setComments={setComments}
              disabled={true}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </Spacing>
          <AgreementSection />
        </>
      );
    case BOTH_PAID_NEGOTIATION:
      return (
        <>
          <Spacing>
            <RenderCommentBox
              expand={false}
              caseId={caseDetails?.id}
              comments={comments}
              setComments={setComments}
              disabled={true}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </Spacing>
          <AgreementSection />
        </>
      );

    // new Stages
    case "awaitingRespondentAcceptance": // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case "noticeToArbitrate": // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case "arbitrationReferenced": // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case "arbitrationNominated": // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case "arbitrationConfirmed": // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case "waitingForArbitratorConfirmation": // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case "firstNoticeSent": // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case "statementOfClaimFiled": // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case "awaitingForSec17Details": // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case "sec17PetitionFiled": // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case "sec17Ordered": // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case "awaitingSec17Execution": // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case "sec17ExtensionMemoFiled": // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case "sec17ExtensionMemoOrdered": // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case "fsOrdered": // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case "attachmentOrdered": // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case "garnisheeProhibitaryOrder": // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case "garnisheeFinalOrder": // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case "sec17Closed": // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case "statementOfDefenceFiled": // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case "rejoinderFromClaimant": // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case "surrejoinderFromRespondent": // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case "2ndNoticeMOM": // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case "evidenceFiledByClaimant": // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case "evidenceFiledByRespondent": // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case "crossExamination": // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case "argumentsByClaimant": // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case "argumentsByRespondent": // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case "negotiationOngoing": // Currently Working On this flow
      return (
        <Spacing>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            isLocalTranscriptionServer={isLocalTranscriptionServer}
          />
        </Spacing>
      );
    case "finalAward": // Currently Working On this flow
      return (
        <>
          <Spacing>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={false}
              comments={comments}
              setComments={setComments}
              disabled={true}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </Spacing>
          <AgreementSection />
        </>
      );
    case "reservedForAward": // Currently Working On this flow
      return (
        <>
          <Spacing>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={false}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </Spacing>
          <AgreementSection />
        </>
      );
    case "awardPassed": // Currently Working On this flow
      return (
        <>
          <Spacing>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={false}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </Spacing>
          <AgreementSection />
        </>
      );
    case "resolutionFailed": // Currently Working On this flow
      return (
        <>
          <Spacing>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={false}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </Spacing>
        </>
      );
    case "settledAndWithdrawn": // Currently Working On this flow
      return (
        <>
          <Spacing>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={false}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </Spacing>
          <AgreementSection />
        </>
      );
    case "updatedAndWithdrawn": // Currently Working On this flow
      return (
        <>
          <Spacing>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={false}
              comments={comments}
              setComments={setComments}
              isLocalTranscriptionServer={isLocalTranscriptionServer}
            />
          </Spacing>
          <AgreementSection />
        </>
      );
    default:
      return null;
  }
}

export default React.memo(MediationState);
